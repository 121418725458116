//includes data for insitutions worked at
const fluteProductions = [
  {
    title: "A Midsummer Night's Dream",
    events: [
      "Marin Sorescu National Theatre International Shakespeare Festival",
      "Craiova Romania May 2024",
      "Itaka Shakespeare Festival",
      "Novi Sad Serbia July 2024",
    ]
  },
  {
    title: "Hamlet Collateral Damage",
    events: [
      "Riverside Studios 2024"
    ]
  },
  {
    title: "Twelfth Night for autistic individuals ",
    events: [
      "Orange Tree Theatre Richmond 2024",
      "Riverside Studios 2024",
    ]
  },
  {
    title: "Hamlet Collateral Damage",
    events: [
      "Riverside Studios 2024"
    ]
  },
  {
    title: "Pericles for Autistic Individuals and Refugee Families",
    events: [
      "Riksteatern, Sweden 2019",
      "Strathmore School, Richmond UK, October 2019",
      "Galeria Katowice, Katowice Poland, October 2019",
      "Centrum Kultury, Lublin Poland with Fundacja Alpha, October 2019",
      "Teatrul Gong, Festival Tanar de Sibiu, Romania, November 2019",
      "Bush Theatre Attic space, London, January 2020",
      "I adapted the production to be accessible online during the COVID-19 Pandemic 2020-2021",
      "Riverside Studios, London UK, Oct 2021 – October 2023",
      "Wolfson Theatre, St. John’s College, Brighton UK, November 2021",
      "Craiova International Shakespeare Festival May 2022",
      "Situational Centre for Ukraine . Sofia Bulgaria May 2022-April 2023",
      "The Old Market Brighton October 2022",
      "Mercury theatre Colchester March 2023",
      "Virginia Primary School Tower Hamlets London March -June 2023",
      "Notre Dame Refugee Centre London March 2023",
      "Beehive Theatre Honiton UK April 2023",
      "Sheffield University Drama Studio UK April 2023",
      "The Brunswick Brighton May 2023",
      "Caravanserai Brighton Fringe Festival May 2023",
      "St Annes and Guardian School Tower Hamlets London June 2023",
      "Hall Croft Stratford upon Avon June 2023",
      "Old Fire Station Oxford June 2023",
      "Itaka Shakespeare Festival, Serbia National Theatre Serbia. July 2023"
    ]
  },
  {
    title: "Pericles",
    events: [
      "Craiova International Shakespeare Festival May 2022",
      "Riverside Studios, London UK, October 2022",
      "Situational Centre for Ukraine . Sofia Bulgaria October 2022",
      "The Old Market Brighton October 2022",
      "Mercury theatre Colchester March 2023",
      "Notre Dame Refugee Centre London March 2023",
      "Beehive Theatre Honiton UK April 2023",
      "Sheffield University Drama Studio UK April 2023",
      "The Brunswick Brighton May 2023",
      "Caravanserai Brighton Fringe Festival May 2023",
      "Hall Croft Stratford upon Avon June 2023",
      "Old Fire Station Oxford June 2023",
      "Itaka Shakespeare Festival, Serbia National Theatre Serbia. July 2023"
    ]
  },
  {
    title: "The Tempest for Autistic Individuals and Their Families",
    events: [
      "The Courtyard Theatre, Stratford upon Avon July 2014",
      "The Wexner centre Columbus Ohio July 2014",
      "Bloomsbury Theatre, Bloomsbury Festival, October 2015",
      "The Help Centre, Los Angeles US, May 2016",
      "Festival de Clasicos en Alcala de Henares, June 2016",
      "Festival of Love, Southbank Centre, July 2016",
      "Orange Tree Theatre, Richmond UK, October 2016",
      "Teatre Lliure, Barcelona, March 2017",
      "I adapted the production to be accessible online during the COVID-19 pandemic. 2020-2021",
      "Riverside Studios, London April 2022-August 2023",
      "The Old Market Theatre May 2022",
      "Craiova International Shakespeare Festival Romania April 2023",
      "Situational Centre for Ukraine. Sofia Bulgaria May 2022-April 2023",
      "Teatro La Plaza, Lima Peru September 2023",
      "Festival Tanar de Sibiu at Teatrul Gong, Sibiu Romania, November 2023"
    ]
  },
  {
    title: "A Midsummer Night’s Dream for Autistic Individuals and Their Families",
    events: [
      "Orange Tree Theatre, Richmond 2017 & 2018",
      "Heart of America Shakespeare Festival, Kansas City MO US 2018",
      "Teatre Lliure, Barcelona, 2018",
      "Bridge Theatre London 2018 & 2019",
      "Festival Tanar de Sibiu at Teatrul Gong, Sibiu Romania, November 2018",
      "Minerva Theatre Chichester 2019",
      "Westminster School PHAB WEEK, June 2019",
      "London Pride Family Garden, June 2019",
      "Marin Sorescu National Theatre International Shakespeare Festival",
      "Craiova Romania May 2024",
      "Itaka Shakespeare Festival",
      "Novi Sad Serbia July 2024",
      "I adapted the production to be accessible online during the COVID-19 pandemic. 2020-2021",
    ]
  },
  {
    title: "Twelfth Night",
    events: [
      "Teatro La Veleta, Almagro, Spain, 2017",
      "Martin Sorescu National Theatre Studio. Craiova International Shakespeare Festival, Romania, April 2018",
      "Teatrul Nottara, Bucharest Romania, April 2108",
      "Bonnington Square Square Gardens, Open Garden Square Weekend London, June 2018",
      "Neuss Globe Shakespeare Festival, June 2018",
      "Minerva Theatre Chichester, January 2019"
    ]
  },
  {
    title: "Hamlet",
    events: [
      "Trafalgar Studios London, December 2016",
      "Gyula Shakespeare Festival Hungary, July 2018"
    ]
  },
  {
    title: "Hamlet, who’s there?",
    events: [
      "Gdansk Shakespeare Festival 2015",
      "Mercury Theatre Colchester 2016",
      "Martin Sorescu National Theatre Studio. Craiova International Shakespeare Festival, Romania, 2016",
      "Park Theatre London 2016",
      "Clasicos en Alcala, Spain 2016",
      "Neuss Globe Germany 2016",
      "Hamletscenen Elsinore, Denmark 2016",
      "Antzokia Teatro Vitoria Spain 2016",
      "Teatro Circo Murcia Spain 2016"
    ]
  }
];


const otherProductions = [

    {
      title: "Sam Shepard One-Act Plays",
      events: [
        "Arts Educational MA Course 2015",
      ],
    },
    {
      title: "Macbeth",
      events: [
        "Royal Central School of Speech and Drama 2015",
        "BADA 2015",
      ],
    },
    {
      title: "The Tempest for autistic individuals and their families",
      events: [
        "Royal Shakespeare Company, Stratford upon Avon & Columbus Ohio 2014",
      ],
    },
    {
      title: "Hamlet",
      events: [
        "Royal Central School of Speech and Drama 2013",
      ],
    },
    {
      title: "Cymbeline",
      events: [
        "BADA 2012",
      ],
    },
    {
      title: "Hamlet all-female cast",
      events: [
        "Mountview Academy of Theatre Arts 2012",
      ],
    },
    {
      title: "Rita, Sue and Bob too",
      events: [
        "Mountview Academy of Theatre Arts, 2012",
      ],
    },
    {
      title: "A Midsummer Night’s Dream",
      events: [
        "BADA 2011",
      ],
    },
    {
      title: "Twelfth Night",
      events: [
        "RADA 2011",
      ],
    },
    {
      title: "The Hounslow Lear",
      events: [
        "Roundhouse, London 2011",
        "The House of Lords, London 2011",
      ],
    },
    {
      title: "The Tempest for autistic individuals and their families",
      events: [
        "Stratford upon Avon RSC 2010",
        "The Armoury, New York RSC 2011",
      ],
    },
    {
      title: "King Lear",
      events: [
        "BADA 2008",
      ],
    },
    {
      title: "The Tempest",
      events: [
        "BADA 2007",
      ],
    },
  ];
  
  const institutions = [
    {
      title: "Shakespeare Summer Programme, International Summer Programmes, University of Cambridge",
      events: [""],
    },
    {
      title: "The Actors Centre, London",
      events: [""],
    },
    {
      title: "BADA, Summer Programme, Balliol College, Oxford",
      events: [""],
    },
    {
      title: "RADA, London",
      events: [""],
    },
    {
      title: "The Drama Centre, London",
      events: [""],
    },
    {
      title: "Cardboard Citizens Theatre Company, London",
      events: [""],
    },
    {
      title: "Mercury Theatre Colchester",
      events: [""],
    },
    {
      title: "Mountview Academy of Theatre Arts, London",
      events: [""],
    },
    {
      title: "St Mary’s University, Twickenham",
      events: [""],
    },
    {
      title: "Shakespeare Institute, University of Birmingham",
      events: [""],
    },
    {
      title: "Ohio State University US",
      events: [""],
    },
    {
      title: "UCLA",
      events: [""],
    },
    {
      title: "Queen Mary University of London",
      events: [""],
    },
    {
      title: "St Edmund Hall, Oxford",
      events: [""],
    },
    {
      title: "The Gdansk Shakespeare Festival",
      events: [""],
    },
    {
      title: "Heart of America Shakespeare Festival Kansas City MO US",
      events: [""],
    },
    {
      title: "University of Cincinnati US",
      events: [""],
    },
    {
      title: "University of Notre Dame US",
      events: [""],
    },
    {
      title: "STA 2018 Cincinnati US",
      events: [""],
    },
    {
      title: "University of Texas",
      events: [""],
    },
    {
      title: "New Bulgarian University",
      events: [""],
    },
    {
      title: "Cincinnati Ensemble Theatre",
      events: [""],
    },
  ];
  
  
  export {fluteProductions, otherProductions, institutions};
  