import Logo from "./Logo";

const HeroSection = ({ children, title, image }) => {

    return (

        <div className='w-full h-[90%] flex max-md:flex-col md:border-x-4 border-white'>
        <div className='md:w-1/2 h-full relative md:border-r-4 border-white'>
            <img className='w-full md:h-[87.55vh] h-[100vh] object-cover' src={image} alt='Kelly hunter acting in Hamlet' />
            <div className='absolute top-0 w-full h-full bg-gradient-to-t from-black to-transparent opacity-40 md:opacity-20'></div>
            <div className='absolute bottom-0  w-full max-md:hidden'>
                <Logo />
            </div> 
            <div className='absolute bottom-0 w-full md:hidden'>
            <h1 className='my-auto text-5xl raleway-font font-bold md:text-center text-white'>{title}</h1>
            </div>      
        </div>
        <div className='md:w-1/2 flex flex-col  w-full max-md:p-4'>
          <div className='max-w-[500px] m-auto max-md:pt-8'>
            <h1 className='my-auto text-5xl raleway-font font-bold text-center max-md:hidden'>{title}</h1>
            <svg className='mx-auto w-full'  height="12" viewBox="0 0 439 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M439 6L429 0.226497V11.7735L439 6ZM0 7H430V5H0V7Z" fill="#5DA399"/>
            </svg>

        <div className='my-auto josefin-font font-light pt-4'>   
            {children}
              
        </div>
        </div>
        </div>
    </div>
    );

};

export default HeroSection;