import React from 'react'
import heroImg from '../assets/education.webp'
import brain from '../assets/education/best-brain_orig.jpg'
import GamerSlide from '../components/GamesSlide'
import HeroSection from '../components/HeroSection'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { institutions } from '../components/otherProductionsData'
const games = [
  {
    title: 'The Cobweb Game',
    content: [
      {
        title: 'The Art',
        video: 'https://player.vimeo.com/video/380529112?h=b43a652ab6',
        description: `Paul and Daniel playing our Cobweb and Bee game during Flute Dreams 2018. This game is derived from deep inside Bottom’s dream when he speaks to the fairy named Cobweb…`,
      },
      {
        title: 'The Science',
        video: 'https://player.vimeo.com/video/375455203?h=6bfccd12ce',
        description: `This video clip show patterns of brain activity in actors performing the roles of Cobweb / Bee in rehearsal. In this scene, Cobweb catches the Bee and then releases it. The actors performed the same roles repeatedly (shown in the little videos on the left) so that we could identify systematic changes in their brain activity. The brain images on the right show the average activity patterns we recorded from the frontal cortex of the brain (red = more brain activity). Surprisingly, the two actors show very similar patterns of activity despite performing different physical movements. This may reflect the close coordination between the actors required for this scene, and further analyses of the data are underway.`,
      },
    ]
    
  },
  {
    title: 'Titania and Bottom Game',
    content: [
      {
        title: 'The Art',
        video: 'https://player.vimeo.com/video/380540899?h=44510a8a7c',
        description: `Me, Josh and Kourosh playing our Titania and Bottom game during Flute Dreams 2018. Kelly and Kourosh are playing Titania, Josh is playing Bottom. At the heart of the game is a moment of extended eye contact, when the tricked eyes of TItania meet the confused eyes of Bottom. At that moment, Titania makes a comical ‘eyes on stalks’ moment making a cartoon like sound – ‘Doyoyoying’ – to alleviate the potential struggle that making eye contact might entail for the person with autism. Kourosh was playing the game in a perfect rhythmic sync with Josh. This is one of the first Hunter Heartbeat games.`,
      },
      {
        title: 'The Science',
        video: 'https://player.vimeo.com/video/375455381?h=5b8258c0a1',
        description: `Patterns of brain activity in actors performing the roles of Bottom and Titania in rehearsal. In this scene, Titania declares her love for Bottom who turns away. As before, the actors performed the same roles repeatedly and we examined the average patterns of brain activity over all the repeats. Both characters show activation of in the middle regions of the prefrontal cortex (linked to social interaction) at the moment of eye contact. We are now analysing the data from other pairs of actors performing the same scene to test for the same effects.`,
      },
    ]
    
  },

];
export default function Education() {
  return (
    <div className='flex flex-col h-full w-screen md:border-4 border-t-0 border-white'>
            <Helmet>
        {/* Meta tags for description, keywords, and canonical link */}
        <title>Kelly Hunter - Education </title>
        <meta
          name='description'
          content='Explore Kelly Hunter’s Hunter Heartbeat Method, sensory games, and scientific research combining theatre and neuroscience.'
        />
        <meta
          name='keywords'
          content='sensory games, theatre, autism, neuroscience, Hunter Heartbeat Method, Kelly Hunter'
        />
        <link rel='canonical' href='https://www.kellyhunter.co.uk/education' />
        {/* Open Graph (OG) meta tags */}
        <meta property='og:title' content='Education - Kelly Hunter' />
        <meta
          property='og:description'
          content='Explore Kelly Hunter’s Hunter Heartbeat Method, sensory games, and scientific research combining theatre and neuroscience.'
        />
        <meta property='og:image' content={heroImg} />
        <meta property='og:url' content='https://www.kellyhunter.co.uk/education' />
        <meta property='og:type' content='website' />
        {/* Twitter meta tags */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:site' content='@KKellyhunter' />
        <meta
          name='twitter:title'
          content='Education - Kelly Hunter'
        />
        <meta
          name='twitter:description'
          content='Explore Kelly Hunter’s Hunter Heartbeat Method, sensory games, and scientific research combining theatre and neuroscience.'
        />
        <meta name='twitter:image' content={heroImg} />
      </Helmet>
      <HeroSection title='Education' image={heroImg} >
      The sensory games of my Hunter Heartbeat Method primarily live in theatrical productions, that can be performed anywhere. The games are also studied for scientific research by Dr Jamie Ward at Goldsmiths, University of London and Professor Antonia Hamilton at UCL. 

I have taught Shakespeare to drama students across the world and have written two books on Shakespeare. 
      </HeroSection>

      <div className='w-full'>
        <div className='bg-green text-5xl font-bold text-white josefin-font text-center p-20'><h2>Research</h2></div>
          <div className='md:flex p-6'>
            <h2 className='md:w-1/4 p-6 my-auto raleway-font text-3xl font-bold' >Shakespeare and the brain</h2>
            <iframe className='md:w-1/2 h-[220px] md:h-[430px] w-full' src="https://www.youtube.com/embed/uh2WtfIEG5I?si=3h9P6amD3qCz5aj5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <p className='md:w-1/4 josefin-font text-sm my-auto p-6 leading-loose'>The Hunter Heartbeat Method is a series of sensory drama games, which allow autistic individuals to share how it feels to be alive and celebrate their identity. Autistic individuals have created this award-winning methodology across the world, working with Artistic Director Kelly Hunter over the last twenty years.</p>
        </div>
        <div className='bg-blue text-white'>
          <div className='md:flex w-full'>
            <div className='relative md:w-3/5'>
            <img src={brain} className='w-full h-full object-cover' alt='onstage respentation of deconstructing the brain' />
            </div>
              
              <div className='flex flex-col md:w-2/5 py-20 px-10 my-auto space-y-2'>
                <h2 className='raleway-font text-4xl font-bold' >Deconstructing the dream</h2>
                <p className='italic josefin-font text-sm leading-loose'>A performance with artists and scientists
                        ​exploring the neuroscience of the imagination</p>
                <p className='raleway-font py-8 leading-loose'>In Deconstructing the Dream, I took some of these sensory games as a starting point for the neuroscience research.  Each game involves a structured but playful social interaction which is short and repeatable, creating the ideal stimuli for scientific investigation. <br />
                In the performance of Deconstructing the Dream, I placed the games in the context of two 30 minute segments of A Midsummer Night's Dream. I then demonstrated how the games relate to the science and are useful for children on the autism spectrum.</p>
                
              </div>
              
            </div>
            <p className='italic bg-white bg-opacity-20  rounded-md p-4 text-sm raleway-font w-full  m-auto py-20 text-xl px-6 md:px-20'>In May 2019 at the Bloomsbury Theatre London Flute Theatre collaborated to create Deconstructing the Dream – my first theatrical performance with artists and scientists exploring the neuroscience of the imagination. These performances included the world’s first scanning of an actor’s brain live on stage.</p>
            <div className='pb-40 pt-20 md:px-40 px-6 space-y-4'>
              <h3 className='raleway-font text-4xl font-bold'>Working with UCL</h3>
              <p className='raleway-font '>The Hunter Heartbeat games are all short, fun to play and crucially they are repeatable. It is within this essential repetition that the people with autism who are taking part can form the habit of playing; their anxiety may begin to be alleviated and they may start to explore their communicative playfulness. This built in repetition creates a perfect opportunity for scientific experimentation, allowing neuroscientists to ask what may be happening in the brain when we are communicating? In Spring 2019, as part of our preparations for Deconstructing the Dream, the actors played the games together in a controlled environment; they were fitted with fNIR caps so that Antonia and her team could collect data from their brains as they were playing. First here is a clip showing the game being played in a workshop situation with actors and people with autism.</p>
              
            </div>
            {/* cobweb game  */}
             <div className='md:flex bg-green'>
              <GamerSlide game={games[0]} />
              </div> 
          </div>
          </div>
        <div className='md:flex bg-blue text-white mt-4 w-full'>
          <GamerSlide game={games[1]} />

      </div>
      <div className='py-20 md:px-40 px-2 raleway-font space-y-4'>
        <h3 className='font-bold text-4xl'>Working with Goldsmiths</h3>
        <p className=''>In 2018, during a series of Flute Theatre’s performances of A Midsummer Nights Dream for young people with autism at The Bridge Theatre, London, Dr Jamie Ward developed an exploratory study using wearables that let us uncover previously unobserved moments of social engagement in some of the children.</p>
        <div><Link to='https://deconstructingthedream.weebly.com/wearable-sensors.html' className='hover:bg-blue hover:text-white transition duration-300 raleway-font font-bold border-b-4 border-green'>Read More &rarr;</Link></div>
      </div>

      <div className='bg-green text-white border-4 border-white flex flex-wrap p-2 md:p-40 gap-8  text-center'>
                <h2 className='col-span-2 text-3xl raleway-font font-bold max-md:pt-8 pb-4 m-auto'>Collaborating Institutions</h2>

                <ul className=' md:columns-5 space-y-2 max-md:px-10'>
                    {institutions.map((production, index) => (
                        <li className='h-auto w-auto  break-inside-avoid-column'>
                        <div key={`fluteProds_${index}`} className='border-dashed border-b-[0.5px] border-white p-2 josefin-font'>
                            <p className='font-bold text- '>{production.title}</p>
                       </div>
                    </li>
                    ))}
                </ul>
            </div>
    </div>

  )
}
