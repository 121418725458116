import React, { useState, useEffect } from 'react';
import { theaterAndFilmData, directionEducationLife } from './TimelineData';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import placeholderImage from '../assets/timeline/logo192.png'
const startYear = 1980;
const endYear = 2025;

export default function Timeline() {
  const [scrollLeft, setScrollLeft] = useState(0);
  const [timelineWidth, setTimelineWidth] = useState(0);
  const [progress, setProgress] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [scrollStartX, setScrollStartX] = useState(0);
  
  const handleScroll = (e) => {
    const scrollPosition = e.target.scrollLeft;
    const timelineContainer = document.getElementById('timeline');
    const timelineWidth = timelineContainer.scrollWidth - timelineContainer.clientWidth;
    const progress = (scrollPosition / timelineWidth) * 100;
  
    setScrollLeft(scrollPosition);
    setProgress(progress);
  };

  const handleProgressBarDragStart = (e) => {
    setDragging(true);
    setDragStartX(e.clientX);
    setScrollStartX(e.target.parentNode.scrollLeft);
  };
  
  const handleProgressBarDragging = (e) => {
    if (!dragging) return;
  
    const dragDistance = e.clientX - dragStartX;
    const timelineContainer = document.getElementById('timeline');
    const timelineWidth = timelineContainer.scrollWidth - timelineContainer.clientWidth;
  
    const scrollDistance = (dragDistance * timelineWidth) / timelineContainer.clientWidth;
    timelineContainer.scrollLeft = scrollStartX - scrollDistance;
  
    const scrollPosition = timelineContainer.scrollLeft;
    const progress = (scrollPosition / timelineWidth) * 100;
  
    setScrollLeft(scrollPosition);
    setProgress(progress);
  };
  
  const handleProgressBarDragEnd = () => {
    setDragging(false);
  };

  const handleSliderTouchStart = (e) => {
    setDragging(true);
    setDragStartX(e.touches[0].clientX);
    setScrollStartX(e.target.parentNode.scrollLeft);
  };

  const handleSliderTouchMove = (e) => {
    if (!dragging) return;

    const dragDistance = e.touches[0].clientX - dragStartX;
    const timelineContainer = document.getElementById('timeline');
    const timelineWidth = timelineContainer.scrollWidth - timelineContainer.clientWidth;

    const scrollDistance = (dragDistance * timelineWidth) / timelineContainer.clientWidth;
    timelineContainer.scrollLeft = scrollStartX - scrollDistance;

    const scrollPosition = timelineContainer.scrollLeft;
    const progress = (scrollPosition / timelineWidth) * 100;

    setScrollLeft(scrollPosition);
    setProgress(progress);
  };

  const handleSliderTouchEnd = () => {
    setDragging(false);
  };
  
  const handleSliderChange = (value) => {
    const timelineContainer = document.getElementById('timeline');
    const width = timelineContainer.scrollWidth - timelineContainer.clientWidth;
  
    const scrollPosition = (value / 100) * width;
  
    timelineContainer.scrollLeft = scrollPosition;
  
    setProgress(value);
    setScrollLeft(scrollPosition);
  };
  

  useEffect(() => {
    // Calculate the timeline width based on your data
    const timelineContainer = document.getElementById('timeline');
    if (timelineContainer) {
      setTimelineWidth(timelineContainer.scrollWidth - timelineContainer.clientWidth);
    }
  }, []);

  useEffect(() => {
    // Update the progress based on scroll position
    setProgress((scrollLeft / timelineWidth) * 100);
  }, [scrollLeft, timelineWidth]);

  const groupByYear = () => {
    const data = {};
    for (let year = startYear; year <= endYear; year++) {
      const theaterData = theaterAndFilmData.filter((item) => item.year === year);
      const directionData = directionEducationLife.filter((item) => item.date === year);
  
      if (theaterData.length || directionData.length) {
        data[year] = data[year] || [];
        data[year].push({
          year,
          theaterData,
          directionData,
        });
      }
    }
    return data;
  };
  
  const marks = {};
  const decades = Math.floor((endYear - startYear) / 10);
  for (let i = 0; i <= decades; i++) {
    const decade = startYear + i * 10;
    marks[(decade - startYear) * (100 / (endYear - startYear))] = (
      <div className='font-logo md:text-lg text-blue' style={{ position: 'absolute', top: '-3pc', left: `${(decade - startYear) * (100 / (endYear - startYear))}%`, transform: 'translateX(-50%)' }}>{decade}</div>
    );
  }

  const timelineData = groupByYear();

  return (
    <div className='justify-center relative w-full h-full'>

      <div className=''>
        <div className='flex space-x-2 md:space-x-4'>
          {/* <label className='font-logo text-xl my-auto'>Year</label> */}
          <div className='font-logo m-auto w-full max-md:pt-8 py-4 max-md:px-4'>
          <Slider
            className='m-auto h-20'
            value={progress}
            marks={marks}
            onChange={(value) => handleSliderChange(value)}
            styles={{ // Use styles.track to set track style
              track: { backgroundColor: '#001F3F' },
              handle: { backgroundColor: 'white', border: '2px solid #001F3F' }, // Adjust handle style here
            }}
            onTouchStart={(e) => handleSliderTouchStart(e)}
            onTouchMove={(e) => handleSliderTouchMove(e)}
            onTouchEnd={() => handleSliderTouchEnd()}
            ariaLabel=' Timeline slider through year intervals'
          >
          <div className='handle' />
            </Slider>
          </div>
          
        </div>
      </div>
      <div className='relative'>
      <div className='bg-gradient-to-l from-white absolute z-10 top-0 right-0 h-full w-20 flex'>
        </div>
      <div className='bg-gradient-to-r from-white absolute z-10 top-0 left-0 h-full md:w-20 flex'>
      </div>
      <ul id='timeline' className='flex flex-nowrap space-x-8 md:pl-80 overflow-x-auto cursor-ew-resize' onScroll={handleScroll}>
        {Object.keys(timelineData).map((year, index) => (
          <li key={index} className=' relative'>
            <label className='font-logo text-blue mx-auto flex w-full sticky left-0'>{year}</label>
            <ul className='space-y-2  columns-1 max-w-auto'>
              {timelineData[year].map((item, itemIndex) => (
                <li key={itemIndex} className='w-full space-y-2 '>
                  {/* Render theaterData and directionData for each year in the decade */}
                  {item.theaterData.length > 0 ? (
                    item.theaterData.map((theaterItem, theaterIndex) => (
                      <div key={theaterIndex} className='border-dashed border-2 w-[280px] md:w-[400px] border-green min-h-60 inline-flex rounded-md p-4 raleway-font'>
                        <div className=' relative w-full'>
                          <span className='josefin-font absolute top-0 right-0'>{theaterItem.year}</span>
                          <p  className='text-sm'>{theaterItem.role}</p>
                          <h3 className='font-bold text-lg '>{theaterItem.production}</h3>
                          <p className='text-xs'>{theaterItem.director}</p>
                          <p className=''>{theaterItem.location}</p>
                          <p className='text-sm italic'>{theaterItem.description}</p>
                          {theaterItem.photo && (
                            <LazyLoadImage className='object-contain' placeholderSrc={placeholderImage} src={theaterItem.photo} alt={theaterItem.production} />
                          )}
                          {theaterItem.video && <div>{theaterItem.video}</div>}
                        </div>
                      </div>
                    ))
                  ) : null} 
                  {item.directionData.length > 0 ? (
                    item.directionData.map((directionItem, directionIndex) => (
                      <div key={directionIndex} className='border-4 border-blue w-[220px] md:w-[400px] inline-flex rounded-md p-4 raleway-font'>
                        <div className='flex flex-col w-full relative'>
                          <span className='josefin-font absolute top-0 right-0'>{directionItem.date}</span>
                          <h3 className='pr-6 font-bold'>{directionItem.title}</h3>
                          <p className='text-xs'>{directionItem.description}</p>
                          {directionItem.photo && (
                            // <img className='object-contain  w-auto pr-2' src={directionItem.photo} alt={directionItem.description} />
                            <LazyLoadImage src={directionItem.photo} alt='directionItem.description' />
                          )}
                          {directionItem.video && <div>{directionItem.video}</div>}
                        </div>
                      </div>
                    ))
                  ) : null}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
      </div>
    </div>
  );
}
