import React from 'react';
import Logo from '../components/Logo';
import HeroSection from '../components/HeroSection';
import heroImage from '../assets/contact-asset.webp';
import ContactForm from '../components/ContactForm';
import { Helmet } from 'react-helmet-async';

export default function Contact() {
  return (
    <div className='w-full'>
      <Helmet>
      <meta
          name='description'
          content='Contact Kelly Hunter directly for directing and teaching enquires by filling out the form on this page. Send an email and expect a prompt response.'
        />
        <meta
          name='keywords'
          content='Shakespeare, theatre, director, Flute Theatre, sensory games, theatre for autistic individuals, teaching, contact'
        />
        <link rel='canonical' href='https://www.kellyhunter.co.uk/contact' />
        {/* Open Graph (OG) meta tags */}
        <title>Contact Me - Kelly Hunter</title>
        <meta
          property='og:title'
          content='Contact Me - Kelly Hunter'
        />
        <meta
          property='og:description'
          content='Contact Kelly Hunter directly for directing and teaching enquires by filling out the form on this page. Send an email and expect a prompt response.'
        />
        <meta property='og:image' content={heroImage} />
        <meta property='og:url' content='https://www.kellyhunter.co.uk/contact' />
        <meta property='og:type' content='website' />
        {/* Twitter meta tags */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:site' content='@KKellyhunter' />
        <meta
          name='twitter:title'
          content='Contact Me - Kelly Hunter'
        />
        <meta
          name='twitter:description'
          content='Contact Kelly Hunter directly by filling out the form on this page. Send an email and expect a prompt response.'
        />
        <meta name='twitter:image' content={heroImage} />
      </Helmet>
      <HeroSection image={heroImage} title='Contact Me'>
        <p className='raleway-font text-sm pb-2'>
          Please send me an email below and I will be in touch shortly.
        </p>
        <ContactForm />
      </HeroSection>
    </div>
  );
}
