import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const GameSlide = ({ game }) => {
  const [showOriginalContent, setShowOriginalContent] = useState(true);

  const handleButtonClick = () => {
    setShowOriginalContent(!showOriginalContent);
  };

  return (
    <div>
      <div className='md:flex py-10 px-2 w-[100vw] max-md:space-y-4'>
        <h3 className='md:w-[20vw] text-3xl font-bold raleway-font m-auto'>{game.title}</h3>
        <div className='md:w-[75vw] md:flex relative'>
          <AnimatePresence mode="wait">
            <motion.div
              key={showOriginalContent ? 'original' : 'new'}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className='w-full md:flex md:space-x-4'
            >
              <iframe className='w-full md:w-2/3 h-60 md:h-[400px]' title="vimeo-player" src={showOriginalContent ? game.content[0].video : game.content[1].video} allowFullScreen></iframe>
              <div className='md:w-1/3 m-auto max-md:py-4'>
                <h4 className='text-2xl font-bold raleway-font'>{showOriginalContent ? game.content[0].title : game.content[1].title}</h4>
                <p className='text-sm josefin-font'>{showOriginalContent ? game.content[0].description : game.content[1].description}</p>
              </div>
            </motion.div>
          </AnimatePresence>
          <button className='max-md:w-full' onClick={handleButtonClick}>
            <div className='m-auto w-auto max-md:w-full md:float-right md:text-right text-2xl pr-8'>&rarr;</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default GameSlide;
