import React, { useState, useEffect } from 'react';
import HeroSection from '../components/HeroSection';
import { createClient } from 'contentful';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Productions from '../components/Productions';
import { Outlet } from 'react-router-dom';
console.log(process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN, "access")

export default function Blog() {
  const [posts, setPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const client = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    });

    client
      .getEntries({
        content_type: 'blogPost',
      })
      .then((response) => {
        setPosts(response.items);
        console.log(response.items, 'successful');
      })
      .catch(console.error);
  }, []);

  const filteredPosts = posts.filter((post) =>
    post.fields.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const formattedDate = (inputDate) => {
    const date = new Date(inputDate);
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const formatted = date.toLocaleDateString('en-GB', options);
    
    const day = date.getDate();
    const suffix = (day >= 10 && day <= 20) || (day % 10 !== 1) ? 'th' : 'st';
    
    return `${day}${suffix} ${formatted.slice(formatted.indexOf(' ') + 1)}`;
  };

  const uniqueMonths = Array.from(
    new Set(
      posts.map((post) => {
        const date = new Date(post.fields.dataPosted);
        return date.toLocaleString('default', { month: 'long', year: 'numeric' });
      })
    )
  );

  return (
    <div className='w-full h-full'>
      <HeroSection title="Blog">
        <p>Text here...</p>
      </HeroSection>

      {/* Blog content */}
      <div className='bg-green w-full h-full flex flex-col'>
        <div className='bg-blue w-full flex'>

          <h1 className='josefin-font text-4xl text-white m-auto py-8'>Insights & Updates</h1>
        </div>
<div className='relative flex '>
<div className=' order-2 sticky top-0 float-right w-[800px] bg-white bg-opacity-40 m-10 p-10 text-white josefin-font border-2 border-dashed'>
          <h3 className='text-2xl'>Find A Blog</h3>
          {/* Filter input */}
          <label>By Title: </label>
          <input
            type='text'
            placeholder='Search by title...'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className='border-2 border-gray-300 rounded-md p-2 m-auto'
          />
          {/* Display unique months */}
          <ul>
            <h4>By Month:</h4>
            {uniqueMonths.map((month, index) => (
              <li key={index}>
                <button onClick={() => console.log(`Filter by ${month}`)}>{month}</button>
              </li>
            ))}
          </ul>
        </div>

        <ul className=' px-4 pl-20 max-w-[1000px] grid grid-cols-2 gap-4 py-20'>
          {filteredPosts.map((post) => (
            <li key={post.sys.id} className='w-full bg-blue text-white h-full'>
              {/* Prepare data for Productions component */}
              {post.fields.asset && (
                <div className='h-full w-full py-4'>
                  <span className='text-right float-right px-4 josefin-font text-xs'>{formattedDate(post.fields.dataPosted)}</span>
                  <Productions
                    data={{
                      title: post.fields.title,
                      images: [post.fields.asset.fields.file.url],
                      description: post.fields.content.content
                        .map((section) =>
                          section.content
                            .map((paragraph) => paragraph.value)
                            .join(' ')
                        )
                        .join(' '),
                    }}
                  />
                  {/* <div className='p-4 text-xs border-y-[0.5px] m-6 mb-0'>
                    {Array.isArray(post.metadata.tags) &&
                      post.metadata.tags.map((tag, indx) => (
                        <span
                          className='bg-white bg-opacity-20 rounded-full py-2 px-4'
                          key={indx}
                        >
                          {tag.sys.id}
                        </span>
                      ))}
                  </div> */}
                </div>
              )}
            </li>
          ))}
        </ul>


        </div>
      </div>
      <Outlet />
    </div>
  );
}
