import React from 'react';
import { Helmet } from 'react-helmet-async';
import HeroSection from '../components/HeroSection'
export default function Landing() {

    return (
        <>
                    <Helmet>
                <title>Kelly Hunter - Theatre Director, Actor, Writer</title>
                <meta
                    name='description'
                    content='Kelly Hunter is a theatre director, actor, and writer. Founder of Flute Theatre, creating sensory games based on Shakespeare for autistic individuals and refugee families.'
                />
                <meta
                    name='keywords'
                    content='Kelly Hunter, theatre director, actor, writer, Flute Theatre, Hunter Heartbeat Method, Shakespeare, sensory games, autistic individuals, refugee families'
                />
                <link rel='canonical' href='https://www.kellyhunter.co.uk' />
                {/* Open Graph (OG) meta tags */}
                <meta
                    property='og:title'
                    content='Kelly Hunter - Theatre Director, Actor, Writer'
                />
                <meta
                    property='og:description'
                    content='Kelly Hunter is a theatre director, actor, and writer. Founder of Flute Theatre, creating sensory games based on Shakespeare for autistic individuals and refugee families.'
                />
                <meta property='og:image' content='https://kelly-hunter-portfolio-assets.ams3.cdn.digitaloceanspaces.com/landing-page.webp' />
                <meta property='og:url' content='https://www.kellyhunter.co.uk' />
                <meta property='og:type' content='website' />
                {/* Twitter meta tags */}
                <meta name='twitter:card' content='summary_large_image' />
                <meta name='twitter:site' content='@KKellyhunter' />
                <meta
                    name='twitter:title'
                    content='Kelly Hunter - Theatre Director, Actor, Writer'
                />
                <meta
                    name='twitter:description'
                    content='Kelly Hunter is a theatre director, actor, and writer. Founder of Flute Theatre, creating sensory games based on Shakespeare for autistic individuals and refugee families.'
                />
                <meta name='twitter:image' content='https://kelly-hunter-portfolio-assets.ams3.cdn.digitaloceanspaces.com/landing-page.webp' />
            </Helmet>
        <HeroSection image='https://kelly-hunter-portfolio-assets.ams3.cdn.digitaloceanspaces.com/landing-page.webp' >
        <p className="josefin-font text-2xl font-light leading-loose">
                    I am a theatre director, actor and writer. 
                    In 2014 I founded Flute Theatre, for whom I am Artistic Director. 
                    Together with the autistic community I have created the Hunter Heartbeat Method, a series of sensory games based on Shakespeare, developed since 2002. These games form the basis of my specialised productions for autistic individuals and refugee families. 
                    </p>
                    
        </HeroSection>
        </>
    );
}
