import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

// emailjs.init(process.env.REACT_APP_EMAILJS_USER_ID);
const ContactForm = () => {
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
        .then((result) => {
            // console.log(result.text);
            resetForm();
        }, (error) => {
            console.log(error.text);
        });
    };

    const resetForm = () => {
        form.current.reset();
      };

  return (
    <form ref={form} onSubmit={sendEmail} className=' flex flex-col'>
      <label className=''>
        Name:
        
      </label>
      <input className=' border-2 rounded border-green border-dashed pb-2 mb-2' type="text" name="name" />
      <label>
        Email Address:
      </label>
      <input className=' border-2 rounded border-green border-dashed pb-2 mb-2' type="email" name="email"  />

      <label>
        Message:
        
      </label>
      <textarea className=' border-2 rounded border-green border-dashed' name="message" />
      <br />
      <button className='raleway-font border-b-4 border-green hover:bg-blue hover:text-white font-bold transition duration-300' type="submit">Submit</button>


    </form>
  );
};

export default ContactForm;
