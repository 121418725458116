import { Route, Routes } from 'react-router-dom';
import './index.css';
import React, { useState } from 'react';
import Landing from './pages/Landing';
import About from './pages/About';
import Directing from './pages/Directing';
import ProductionPopUp from './components/ProductionPopUp';
import Header from './components/Header';
import Footer from './components/Footer';
import HunterHeartbeat from './pages/HunterHeartbeat';
import Education from './pages/Education';
import { AnimatePresence} from 'framer-motion';
import Books from './pages/Books';
import Contact from './pages/Contact';
import ReactGA from 'react-ga4'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Blog from './pages/Blog';

function App() {

  const location = useLocation();
  //stop scrolling if a popup is open
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closePopup = () => {
    setPopupOpen(false);
    document.body.style.overflow = 'auto';
  };

  const GA_TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;

  // Initialize ReactGA with your tracking ID
//   ReactGA.initialize(GA_TRACKING_ID);
// console.log(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID, "tracking ID")
// useEffect(() => {
//   ReactGA.initialize(GA_TRACKING_ID);
// }, [GA_TRACKING_ID]);

// useEffect(() => {
//   ReactGA.send({ hitType: 'pageview', page: location.pathname });
// }, [location]);

  return (
    <AnimatePresence >
    <div className="relative">
      <div className='h-[13vh] max-md:absolute top-0 z-10 w-full'>
      <Header />  
      </div>
      
      <main className='flex '>
        <Routes>
          <Route path='/' element={<Landing />} />
          <Route path='/about-me' element={<About />} />
          <Route path='contact' element={<Contact />} />
          <Route path='/directing' element={<Directing />} >
            <Route path=':production' element={<ProductionPopUp />} />
          </Route>
          <Route path='/hunter-heartbeat' element={<HunterHeartbeat />} />
          <Route path='/education' element={<Education />} />
          <Route path='/books' element={<Books />} > 
            <Route path=':production' element={<ProductionPopUp />} />
          </Route>
          <Route path='/blog' element={<Blog />} >
            <Route path=':production' element={<ProductionPopUp />} />
            </Route>
            <Route path='/*' element={<Landing />} />
        </Routes>
        

      </main>
      <Footer />
    </div>
    </AnimatePresence>
  );
}

export default App;
