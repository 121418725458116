import React from 'react'
import { Link } from 'react-router-dom';

export default function Productions({data, type}) {

    const {title, images, description, reviews, link } = data;
    console.log(data, 'are the reviews here??')
  return (
    <div className='space-y-4 flex flex-col w-full h-full transition-color duration-75 hover:text-white hover:bg-blue p-4'>
        <Link to={`${title}`} state={{title: title, images: images, description: description, reviews, link,  type: type}} >
          <img className='w-full h-60 object-cover' src={images[0]} alt='Katy and tom acting in hamlet'/>
          <div className='h-auto'>
            <h3 className='josefin-font font-bold text-lg pt-2 '>{title}</h3>
            <p className='raleway-font text-sm pb-4'>{description.slice(0,100)}...</p>
            <span className='raleway-font font-bold border-b-4 border-green'>Learn More &rarr; </span>
          </div>
        </Link>

    </div>
  )
}
