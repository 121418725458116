const theaterAndFilmData = [
    {
      year: 1980,
      endDate: 1982,
      role: "The Mistress",
      production: "EVITA",
      director: "Hal Prince",
      location: "Prince Edward Theatre",
      description: "Running until 1982",
      photo: require('../assets/timeline/EVITA 1980.webp'),
    },
    {
      year: 1983,
      endDate: null,
      role: "JEAN SEBERG",
      production: "JEAN SEBERG",
      director: "Peter Hall",
      location: "National Theatre",
      description: "",
      photo: require('../assets/timeline/jean seberg 1983.webp'),
    },
    {
      year: 1985,
      endDate: null,
      role: "Constance",
      production: "SHE STOOPS TO CONQUER",
      director: "Giles Block",
      location: "National Theatre",
      description: "Running until 1986",
      photo: null,
    },
    {
      year: 1985,
      endDate: 1986,
      role: "Linda",
      production: "A CHORUS OF DISAPPROVAL",
      director: "Alan Ayckbourn",
      location: "National Theatre",
      description: "Running until 1986",
      photo: null,
    },
    {
      year: 1986,
      endDate: null,
      role: "Woman 1",
      production: "ME, MYSELF & I",
      director: "Alan Ayckbourn",
      location: "National Theatre",
      description: null,
      photo: null,
    },
    {
      year: 1987,
      endDate: null,
      role: "Sally Bowles",
      production: "CABARET",
      director: "Jerome Savery",
      location: "Theâtre Mogador Paris",
      description: "In French",
      photo: require('../assets/timeline/CABARET 1987.webp'),
    },
    {
      year: 1987,
      endDate: null,
      role: "Sally Bowles",
      production: "CABARET",
      director: "Gillian Lynne",
      location: "Strand Theatre, West End (now Novello Theatre)",
      description: null,
      photo: null,
    },
    {
      year: 1991,
      endDate: 1992,
      role: "Lola",
      production: "THE BLUE ANGEL",
      director: "Trevor Nunn",
      location: "RSC / West End",
      description: "Laurence Olivier Nomination Best Actress in Musical Laurence Olivier Awards",
      photo: null,
      video: (<iframe className='w-full' width="560" height="315" src="https://www.youtube.com/embed/8HJfK6hwUgE?si=AKWNrkNoikiVtPr1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>),
    },
    {
      year: 1991,
      endDate: 1992,
      role: "Mariana",
      production: "MEASURE FOR MEASURE",
      director: "Trevor Nunn",
      location: "RSC",
      description: null,
      photo: null,
    },
    {
      year: 1993,
      endDate: null,
      role: "The Woman",
      production: "TRIOS",
      director: "Simon Usher",
      location: "Riverside Studios",
      description: null,
      photo: null,
    },
    {
      year: 1993,
      endDate: null,
      role: "Frances",
      production: "NOT FADE AWAY",
      director: "Simon Usher",
      location: "Bush Theatre",
      description: null,
      photo: null,
    },
    {
      year: 1993,
      endDate: null,
      role: "Being Human",
      production: "Bill Forsyth",
      director: null,
      location: null,
      description: null,
      photo: null,
    },
    {
      year: 1993,
      endDate: null,
      role: "Resort to Murder",
      production: "Bruce Macdonald",
      director: null,
      location: null,
      description: null,
      photo: null,
    },
    {
      year: 1993,
      endDate: null,
      role: "PRIME SUSPECT III",
      production: "David Drury",
      director: null,
      location: null,
      description: null,
      photo: null,
    },
    {
      year: 1994,
      endDate: null,
      role: "Rosalind",
      production: "AS YOU LIKE IT",
      director: "Stephen Unwin",
      location: "English Touring Theatre",
      description: "TMA Best Actress Award",
      photo: null,
    },
    {
      year: 1994,
      endDate: null,
      role: "Nora",
      production: "A DOLL’S HOUSE",
      director: "Stephen Unwin",
      location: "English Touring Theatre",
      description: null,
      photo: require('../assets/timeline/A DOLLS HOUSE 1994.webp'),
    },
    {
      year: 1995,
      endDate: null,
      role: null,
      production: "SILENT WITNESS SEASON 1, EPISODE 1",
      director: "Harry Hook",
      location: null,
      description: null,
      photo: null,
    },
    {
      year: 1996,
      endDate: null,
      role: "Dorothy Wordsworth",
      production: "EXQUISITE SISTER",
      director: "Simon Usher",
      location: "West Yorkshire Playhouse/Edinburgh Festival",
      description: null,
      photo: null,
    },
    {
      year: 1997,
      endDate: null,
      role: null,
      production: "CLOSE RELATIONS",
      director: "Michael White",
      location: null,
      description: null,
      photo: null,
    },
    {
      year: 1998,
      endDate: 1999,
      role: "Isobel",
      production: "TALK OF THE CITY",
      director: "Stephen Poliakoff",
      location: "RSC Swan Theatre / Young Vic",
      description: null,
      photo: require('../assets/timeline/Talk of the city 1998.webp'),
    },
    {
      year: 1998,
      endDate: null,
      role: "THE LUZHIN DEFENCE",
      production: "Marlene Gorris",
      director: "Film",
      location: null,
      description: null,
      photo: null,
    },
    {
      year: 1999,
      endDate: null,
      role: "THE HOLE",
      production: "Nick Hamm",
      director: null,
      location: null,
      description: null,
      photo: null,
    },
    {
      year: 1999,
      endDate: null,
      role: "PERFECT STRANGERS",
      production: "Stephen Poliakoff",
      director: "BBC",
      location: null,
      description: null,
      photo: require('../assets/timeline/perfect strangers 1999.webp'),
    },
    {
      year: 2000,
      endDate: null,
      role: "Beatrice",
      production: "CUCKOOS",
      director: "Peter Hall",
      location: "National Theatre / Gate Theatre",
      description: null,
      photo: require('../assets/timeline/cuckoos 1999.webp'),
    },
    {
      year: 2001,
      endDate: 2002,
      role: "Constance",
      production: "KING JOHN",
      director: "Gregory Doran",
      location: "RSC",
      description: null,
      photo: null,
    },
    {
      year: 2002,
      endDate: null,
      role: "Lisa Pendry",
      production: "THE LUCKY ONES",
      director: "Matthew Lloyd",
      location: "Hampstead Theatre",
      description: null,
      photo: null,
    },
    {
      year: 2003,
      endDate: null,
      role: null,
      production: "VANITY FAIR",
      director: "Mira Nair",
      location: null,
      description: null,
      photo: null,
    },
    {
      year: 2006,
      endDate: 2013,
      role: "Lucy Samsa",
      production: "METAMORPHOSIS",
      director: "David Farr and Gisli Gardarson",
      location: "Vesturport/BAM New York/World Tour",
      description: null,
      video: (<iframe  src="https://www.youtube.com/embed/doF0oEGjUrM?si=qA8iyF44jXsy0CEV" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>),
      photo: require('../assets/timeline/Picture 1.jpg'),
    },
    {
      year: 2007,
      endDate: null,
      role: null,
      production: "ELIZABETH: THE GOLDEN AGE",
      director: "Shekhar Kapur",
      location: null,
      description: null,
      photo: null,
    },
    {
      year: 2008,
      endDate: null,
      role: "Shadow architect",
      production: "DOCTOR WHO",
      director: "Graham Harper",
      location: null,
      description: null,
      photo: null,
    },
    {
      year: 2009,
      endDate: 2011,
      role: "Goneril",
      production: "KING LEAR",
      director: "David Farr",
      location: "RSC / Stratford upon Avon, Roundhouse London Armoury New York",
      description: null,
      photo: require('../assets/timeline/about-me-1.webp'),
    },
    {
      year: 2009,
      endDate: 2011,
      role: "Hermione",
      production: "THE WINTER’S TALE",
      director: "David Farr",
      location: "RSC / Stratford upon Avon, Roundhouse London, Armoury New York",
      description: null,
      photo: require('../assets/timeline/hermione.jpg'),
    },
    {
        year: 2013,
        endDate: null,
        role: "Mrs Alving",
        production: "GHOSTS",
        director: "Stephen Unwin",
        location: "Rose Theatre Kingston, English Touring Theatre",
        description: null,
        photo: require('../assets/timeline/GHOSTS 2013.webp'),
      },
  ];


  const directionEducationLife = [
    {
      date: 1988,
      title: 'Shop Assistance - Covent Garden event for people with AIDS and HIV',
      description: 'Works at Terrence Higgins Trust from 1988-89 fundraising and advocating for the rights of people with AIDS and HIV',
      photo: [require('../assets/timeline/shop assistance 1988.webp')],
    },
    {
      date: 2002,
      title: 'Touchstone Theatre. ',
      description: 'Founder and Artistic Director 2002-2006. Glebe School, Beckenham. Hunter Heartbeat games created 2002-2006. Beckton Lear created 2005-2006',
      images: [],
    },
    {
      date: 2007,
      title: 'The Tempest',
      description: 'BADA, Oval House London. Director and adaptor',
      images: [],
    },
    {
      date: 2008,
      title: 'King Lear',
      description: 'BADA, Oval House London. Director and adaptor',
      photo: [require('../assets/timeline/Picture 2.webp')],
    },
    {
        date: 2009,
        title: 'Hay Lane School (Now The Village School), Colindale',
        description: 'Develops Hunter Heartbeat sensory games, working with autistic individuals who are non-verbal and in need of 24/7 care. The project began as a collaboration with the RSC and ended in 2016 as a collaboration with Flute Theatre.',
        photo: [require('../assets/timeline/Picture 3.jpg')],
      },
      {
        date: 2010,
        title: 'The Tempest for autistic individuals',
        description: 'Director & Adapter. RSC First sharing. Stratford upon Avon.',
        photo: [require('../assets/timeline/Picture 4.jpg')],
      },
      {
        date: 2011,
        title: 'The Tempest for autistic individuals',
        description: 'Director & Adapter. RSC. A Sharing. The Armoury New York.',
        images: [],
      },
      {
        date: 2011,
        title: 'Twelfth Night',
        description: 'RADA Director and Adaptor.',
        images: [],
      },
      {
        date: 2012,
        title: 'Hamlet, all-female version',
        description: 'Mountview Drama School. Director and Adaptor.',
        images: [],
      },
      {
        date: 2012,
        title: 'Rita Sue and Bob too',
        description: 'Mountview Drama School. Director.',
        images: [],
      },
      {
        date: 2012,
        title: 'Cymbeline',
        description: ' BADA. Oval House. Director and Adaptor.',
        images: [],
      },
      {
        date: 2012,
        title: 'Hunter Heartbeat Research Project. Ohio State University',
        description: 'Project ran at OSU from 2012-2016.',
        images: [],
      },

      {
        date: 2014,
        title: "Shakespeare's Heartbeat Book ",
        description: 'Drama games for children with autism. Published by Routledge. Author.',
        images: [],
      },
      {
        date: 2014,
        title: `AHRC Award for innovation.`,
        description: `"This Island's mine" by Miriam Rey, based on the Hunter Heartbeat games.`,
        images: [],
      },
      {
        date: 2014,
        title: 'The Tempest for autistic individuals. ',
        description: 'Director & Adapter. RSC/OSU Courtyard Theatre, Stratford upon Avon, Wexner Centre Columbus Ohio. Production continues to be performed across the world and is adapted as accessible online during the pandemic. Most recently directed the production in Lima Peru, Autumn 2023.',
        images: [],
      },
      {
        date: 2014,
        title: 'Founds and leads Flute Theatre. ',
        description: 'November 2014. Company continues to create original productions of Shakespeare, touring the UK and internationally. Productions include those for autistic individuals based on Hunter Heartbeat Method.',
        images: [],
      },
      {
        date: 2015,
        title: "Hamlet, who's there? Gdansk Shakespeare Festival. ",
        description: 'Flute Theatre. Director, Adaptor, and Actor. Production performs across international festivals until 2018, including Craiova International Shakespeare Festival Romania.',
        photo: [require('../assets/timeline/Picture 5.jpg')],
        video: (<iframe className='w-full' src="https://player.vimeo.com/video/165026023?h=f8cf3b2e20" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>),
      },
      {
        date: 2016,
        title: 'Cracking Shakespeare',
        description: 'Published by Bloomsbury. Author.',
        photo: [require('../assets/timeline/crackingshakespeare-launch.webp')],
      },
      {
        date: 2016,
        title: `Flute Theatre international and UK touring. `,
        description: `Tours across Europe as director and actor with "Hamlet, who's there?" and "The Tempest for autistic audiences," including first performances at Orange Tree Theatre Richmond UK.`,
        photo: [require('../assets/timeline/Picture 6.jpg')],
      },
      {
        date: 2017,
        title: 'La Tempestad (in Catalan)',
        description: 'Teatre Lliure, Barcelona. Director and Adaptor. First performances of productions for autistic people in Catalan. Beginning of a long-term project with Escuela Especial de Monserrat Montera, Granollers. Project ongoing.',
        photo: [require('../assets/timeline/Picture 7.jpg')],
      },
      {
        date: 2017,
        title: "A Midsummer Night's Dream for autistic individuals. ",
        description: `Director and Adaptor. Creates A Midsummer Night's Dream for autistic individuals in co-production with Orange Tree Theatre Richmond UK. Production tours until 2019, including two residencies at the Bridge Theatre London. Production is adapted as accessible online during the pandemic.`,
        images: [],
      },
      {
        date: 2017,
        title: 'Neuroscience Research Project. ',
        description: 'UCL and Goldsmiths University Meets Dr. Jamie Ward and begins a long-term research project using Hunter Heartbeat games.',
        images: [],
      },
      {
        date: 2017,
        title: 'Twelfth Night. ',
        description: 'Flute Theatre. Director and Adaptor. Almagro Festival, Spain. Production tours Europe and the UK from 2017-2019, including Craiova International Shakespeare Festival Romania.',
        images: [],
      },
      {
        date: 2018,
        title: 'Flute Theatre international and UK touring.',
        description: 'Tours across Europe, the UK, and the US as a director with "Twelfth Night" and "A Midsummer Night\'s Dream for autistic audiences," including first performances for autistic audiences in Romanian at Teatrul Gong, Sibiu.',
        images: [],
      },
      {
        date: 2018,
        title: 'Flute Dreams pilot project. ',
        description: 'Bush Theatre London. Director. Founded a community project for autistic people in West London.',
        photo: [require('../assets/timeline/Picture 8.jpg')],
      },
      {
        date: 2018,
        title: "A Midsummer Night's Dream for autistic individuals. ",
        description: '(In Catalan) Teatre Lliure, Barcelona. Director and Adaptor.',
        images: [],
      },
      {
        date: 2019,
        title: `Flute Dreams community project awarded three years funding from John Lyon's Charity.`,
        description: 'Offer regular artistic opportunities for participation for the most profoundly autistic individuals. Continue throughout the pandemic and build strong ties of trust with the community of families.',
        photo: [require('../assets/timeline/Picture 9.jpg')],
      },
      {
        date: 2019,
        title: 'Awarded MBE for services to theatre',
        description: '',
        images: [],
      },
      {
        date: 2019,
        title: 'St John\'s College, Brighton.',
        description: 'Collaborative project with autistic learners. Director. Begin working with St John\'s College Brighton. Deliver over 200 online performances for their autistic learners during the pandemic. Continue to deliver residencies in Brighton post-pandemic.',
        photo: [require('../assets/timeline/Picture 10.jpg')],
      },
      {
        date: 2019,
        title: 'Pericles for autistic individuals.',
        description: 'Director and adaptor Folkteatern, Sweden. Production tours Europe and the UK, performing in Swedish, Polish, and Romanian. Production is adapted as accessible online during the pandemic. Production becomes part of the award-winning double Pericles in 2022/23.',
        photo: [require('../assets/timeline/Picture 11.jpg')],
      },
      {
        date: 2019,
        title: 'Wildcats Last Waltz by Joshua Welch.',
        description: 'Director. White Bear, Riverside Studios London. Assembly Rooms Edinburgh Festival 2023.',
        images: [],
      },
      {
        date: 2019,
        title: 'Deconstructing The Dream, Bloomsbury Theatre. ',
        description: 'Director with Dr. Jamie Ward and Dr. Antonia Hamilton. A collaborative performance of arts and science featuring the first live MRI scanning of an actor onstage.',
        images: [],
      },
      {
        date: 2020,
        title: "Pericles, A Midsummer Night's Dream and The Tempest for autistic individuals",
        description: 'Adapted to be accessible online during COVID-19 pandemic. Adapts all three specialized productions to be available online for our community of autistic individuals. Delivers 967 live online performances across four continents.',
        photo: [require('../assets/timeline/Picture 12.jpg')],
      },
      {
        date: 2021,
        title: "Flute Dreams community project awarded another three years funding from John Lyon's Charity.",
        description: 'Returns to real space at Riverside Studios to continue providing regular artistic opportunities for artistic participation for the most profoundly autistic individuals in the West London area.',
        photo: [require('../assets/timeline/Pericles Stills-32 copy 2.webp')],
      },
      {
        date: 2022,
        title: 'Pericles - Craiova Shakespeare Festival Romania.',
        description: 'Director and adaptor. Perform Double Pericles, working with ANCAAR.',
        images: [],
      },
      {
        date: 2022,
        title: 'Pericles for Ukrainian Refugees.',
        description: 'Situational Centre, Sofia, Bulgaria. Director. Creates Project for Ukrainian refugees using Hunter Heartbeat games. Performances in Russian and Ukrainian.',
        photo: [require('../assets/timeline/Picture 13.jpg')],
      },
      {
        date: 2023,
        title: 'Double Pericles tour. ',
        description: 'Director and adaptor. 2023 OFFCOMM award for best overall production of a short run. Production tours to major international festivals, refugee centers, and schools for autistic children across the UK, Romania, Serbia, and Bulgaria.',
        photo: [require('../assets/timeline/Picture 14.jpg')],
      },
      {
        date: 2023,
        title: 'The Tempest - Craiova International Shakespeare Festival. ',
        description: 'Director, Adaptor, and Actor. Production created to celebrate the 400th anniversary of Shakespeare\'s First Folio. Shared the role of Prospero with Katy Stephens.',
        photo: [require('../assets/timeline/Picture 15.jpg')],
      },
      {
        date: 2023,
        title: 'The Tempest - Teatro La Plaza, Lima Peru',
        description: `Adapter and Director
        Directs production of The Tempest for autistic individuals in Spanish with Peruvian actors. Productions tours Peru autumn 2023. Project in collaboration with People's Palace Project. `,
        photo: [require('../assets/timeline/La Tempestad.webp')],
        video: (<iframe src="https://drive.google.com/file/d/1SB0GpZ92K-HE7phzWtNxCrtrgaPbzxvi/preview" allow="autoplay"></iframe>)
      },
      {
        date: 2024,
        title: 'Twelfth Night for autistic individuals',
        description: `Flute Theatre Director and adaptor`,
        photo: [require('../assets/timeline/twelth_Night_a_photo_1.webp')],
        video: (<iframe className='w-full' src="https://player.vimeo.com/video/912386737?h=d6e5bbf5e2" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>),
      },
      {
        date: 2024,
        title: 'Hamlet Collateral Damage',
        description: `Flute Theatre Director and adaptor. 
        World premiere Riverside Studios 2024`,
        photo: [require('../assets/timeline/hamlet_col_dam_photo_1.webp')],
        video: (<iframe className='w-full' src="https://player.vimeo.com/video/914472873?h=9f8743d1b0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>),
      },
      {
        date: 2024,
        title: `A Midsummer Night's Dream `,
        description: `Flute Theatre Director and adaptor.`,
        photo: [require('../assets/directing/midsummer/image_1.webp')],
        video: (<iframe className='w-full object-contain h- pt-1' width="560" height="315" src="https://www.youtube.com/embed/jfhJIMwkMkw?si=O47T6JnE2o-_bRdQ" title="YouTube video player" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>),
      },

      
  ];
  
  export {directionEducationLife, theaterAndFilmData} ;