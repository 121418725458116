const productions = [
    {
        title: "A Midsummer Night's Dream",
        images: [
        require('../assets/directing/midsummer/image_1.webp'),
        require('../assets/directing/midsummer/image_2.webp'),
        require('../assets/directing/midsummer/image_3.webp'),
        require('../assets/directing/midsummer/image_4.webp'),
        require('../assets/directing/midsummer/image_5.webp'),
        require('../assets/directing/midsummer/image_6.webp'),],
        description: `
        Directing this play makes anyone feel like Peter Quince and this production was no exception as I endlessly gave out parts, scripts and instruction to my team - the big difference being that my particular team are incredible at what they do. I couldn't be luckier. At the heart of the play is a sensory world turned upside down - seeing ears, hearing eyes, tasting hands and speaking hearts - eyes that love and minds that see. Our company of seven performers take all the roles endlessly transforming themselves from spirits who fly faster than thought to donkeys that barely move to overs who only see as far as their own love lives. Only Puck remains a constant - shifting in his own body but never playing out other parts. 

        `
    },
    {
        title: "Twelth Night for Autistic Individuals",
        images: [
        require('../assets/directing/twelth night autistic/photo_1.webp'),
        require('../assets/directing/twelth night autistic/photo_2.webp'),
        require('../assets/directing/twelth night autistic/photo_3.webp'),
        require('../assets/directing/twelth night autistic/photo_4.webp'),
        require('../assets/directing/twelth night autistic/photo_5.webp'),
        require('../assets/directing/twelth night autistic/photo_6.webp'),],
        description: `My fourth show for autistic individuals and the happiest, funniest and most musical by far. For this production we finally have a new floorcloth for our autism shows designed with care and attention by Alice Hallifax in yellows, oranges and russetts to bring sunlight, warmth and laughter into the space. Twelfth NIght begins by asking us to play in a world of music and love which is exactly what we have done. The games are funny and irreverent, replacing Shakespeare's yellow stockings with a sea of yellow hats and offering  the chance to hide behind veils of orange and yellow for Olivia's initial vows of celibacy. 
        In nature there's no blemish but the mind, 
        none can be called deformed but the unkind
        Act 3 sc4
        The show was created at a time of intense human suffering in the world and we have all sought refuge in its warmth and kindness. `
    },
    {
        title: "Hamlet Collateral Damage",
        images: [
        require('../assets/directing/hamlet collateral damage/photo_1.webp'),
        require('../assets/directing/hamlet collateral damage/photo_2.webp'),
        require('../assets/directing/hamlet collateral damage/photo_3.webp'),
        require('../assets/directing/hamlet collateral damage/photo_4.webp'),
        require('../assets/directing/hamlet collateral damage/photo_5.webp'),
    ],
        description: `I've come back to Hamlet to make an adaptation of this play in direct response to Israel's genocide of the  Palestinian people and the continuing unjust  imprisonment of Julian Assange. These modern day horrors are linked; the smiling, villainous states wreaking havoc and destruction on the innocent while the world watches on not taking action. 
        In this adaptation Hamlet and Ophelia are together unable to offer each kindness and comfort, but instead destroying each other, both falling blindly and brutally into their friendless existences of madness. `
    },    
    {
        title: "Hamlet",
        images: [require('../assets/directing/hamlet/1.jpg'),
        require('../assets/directing/hamlet/2.jpg'),
        require('../assets/directing/hamlet/3.jpg'),
        require('../assets/directing/hamlet/4.jpeg'),
        require('../assets/directing/hamlet/5.jpg'),
        require('../assets/directing/hamlet/6.jpg'),
        require('../assets/directing/hamlet/7.jpg'),
        require('../assets/directing/hamlet/8.jpg'),
        require('../assets/directing/hamlet/9.jpg')],
        description: `Philip Roth says, “Its amazing that something as tiny as the self should contain contending subselves and that these subselves should themselves be constructed of subselves and on and on and on”.

        I would make a completely different version of Hamlet today.
        
        In the version I made in 2015, first titled “Hamlet Who’s There?” The show takes place in two locations, Hamlet’s bedroom and the graveyard and spans 6 hours, beginning at midnight on the wedding party of Gertrude and Claudius. Everyone is divided from themselves. Purposefully so. The ghost of Hamlet’s father screams, worms, and fights its way out of Hamlet’s possessed body. Laertes pulsates his way through a blistering drum solo trying to connect with his friend. The black and inky spots that plague Gertrude’s mind’s eye shake her very core. Ophelia crawls onto the stage with the dead bleeding body of Polonius on top of her. The readiness is all, and the rest is silence.

        We premiered the play at Gdansk Shakespeare Festival in August 2015. Probably it was never better than that night. Over the next three years the production became slicker, better lit and more rehearsed, with several cast changes, notably a brilliant Gertrude from Katy Stephens. The night in Poland was unforgettable and set Flute theatre (and me) off on a love affair with European Shakespeare festivals, for which the flame still burns today.`
    },
    {
        title: "The Tempest",
        images: [require('../assets/directing/the tempest/1.jpg'),
        require('../assets/directing/the tempest/2.jpg'),
        require('../assets/directing/the tempest/3.jpg'),
        require('../assets/directing/the tempest/4.jpg'),
        require('../assets/directing/the tempest/5.jpg'),
        require('../assets/directing/the tempest/6.jpg'),
        require('../assets/directing/the tempest/7.jpg'),
        require('../assets/directing/the tempest/8.jpg'),
        require('../assets/directing/the tempest/9.jpg')],
        description: `We performed this adaptation on April 23rd 2023 at the Craiova International Shakespeare Festival as part of their celebration of the 400th anniversary of Shakespeare’s first folio. We then performed it the week later in Sofia Bulgaria at the New Bulgarian University. 

        I created this production with the Pericles company whilst we were touring our double Pericles shows around the UK and Europe in Spring 2023. During one of Pericles performances for Ukrainian refugee children in Sofia Bulgaria we were playing ‘The Storm Game’ - where Thaisa and Pericles lose each other. In response to the solemn mood of the game a young boy picked up a singing bowl and playing it in slow chimes, walking anti-clockwise around the outside of the circle as if he were a priest offering solemn last rites on the occasion of great sadness. It was one of the most instinctive and honest reactions to one of my games that I had ever witnessed and months later it inspired me when I was adapting The Tempest for the company. I used the boys ‘circle of sadness’ to express Antonio’s penance. For the duration if the show, Antonio walked in circles painfully slowly, just as the boy had done, around and around the perimeter of the space, playing with the same singing bowl. It felt as if he were on the edge of the island, on the edge of his nerves and at the edge of grief. I would never have come up with this if I hadn’t experienced the young boy’s inventiveness. 
        `
    },
    {
        title: "Twelfth Night",
        images: [require('../assets/directing/twelfth night/1.jpg'),
        require('../assets/directing/twelfth night/2.JPG'),
        require('../assets/directing/twelfth night/3.JPG'),
        require('../assets/directing/twelfth night/4.JPG'),
        require('../assets/directing/twelfth night/5.jpg'),
        require('../assets/directing/twelfth night/6.JPG'),
        ],
        description: `In his masterpiece, If this is a man, Primo Levi has a chapter named The Drowned and The Saved. In it, he tells us not of those who were literally saved from the gas chambers but rather those whose cellular attitude to life, their “bounce”, seemed to save them moment to moment. 

        Shakespeare drowns Ophelia (his tragic young female protagonist) at the end of Hamlet, and he saves Viola (his comic young female protagonist) from drowning at the beginning of 12th Night. He wrote both plays around the same time.  
        
        At the end of my production of Hamlet, Ophelia clung to a long ribbon of material – the length of the stage - walking as the ghost of her drowned self, haunting Laertes.  
        At the beginning of my Twelfth Night, the same actress who had played Ophelia was again walking as if in death, but thirty seconds into the play a clown splashes her with a bucket of cold water, the carnival music begins, and she is saved. 
        
        I understood Twelfth Night as the necessary “bounce back” from Hamlet, music is the food of love, and we must play on. 
        `
    },
    {
        title: "Pericles",
        images: [require('../assets/directing/pericles/1.jpg'),
        require('../assets/directing/pericles/2.jpg'),
        require('../assets/directing/pericles/3.jpg'),
        require('../assets/directing/pericles/4.jpg'),
        require('../assets/directing/pericles/5.jpg'),
        require('../assets/directing/pericles/6.jpg'),
        require('../assets/directing/pericles/7.jpg'),
        require('../assets/directing/pericles/8.JPG'),
        require('../assets/directing/pericles/9.JPG'),
        require('../assets/directing/pericles/10.jpg'),
        require('../assets/directing/pericles/11.JPG'),
        require('../assets/directing/pericles/12.JPG'),
        require('../assets/directing/pericles/13.JPG'),
        require('../assets/directing/pericles/14.JPG'),
        require('../assets/directing/pericles/15.JPG'),
        require('../assets/directing/pericles/16.JPG'),
        require('../assets/directing/pericles/17.jpg'),
        require('../assets/directing/pericles/18.jpg'),
        require('../assets/directing/pericles/19.jpg'),
        ],
        description: `This was the easiest and most enjoyable experience I have had of adapting and directing for Flute. For one simple reason, the strength and talent of the existing company. For whom I adapted the play.  We had been through the pandemic together, working every day online for a year and a half and we had come out the other side with desire, trust and humour.  We already had a production of Pericles for autistic people, so we knew the play.  Josh Welch and Tash Haward had talked about their understanding of loss (both lost parents whilst they were still children). It was a complete joy to direct them as Pericles and Thaisa (and Marina) and watch them blossom. 

        Cerimon was inspired by Mohit Mathur. In 2021, we had come back to real space performances, including working with profoundly autistic individuals as part of our community project. One hot summer afternoon we were playing with rhythms; I was drumming in a corner and there perhaps six people in the room. One child stayed in a corner, seemingly lifeless, his sensory overload preventing him from being touched; if you got too close, he would kick and scratch. From nowhere Mohit seemed to reach inside the aura of the child, took his hands and began to dance him to life. The child showed no resistance and partnered Mo around the room, allowing himself to be lifted from the ground, stamp in rhythms and be fully engaged as a couple. It felt miraculous. 
        When we came to create the sequence in Pericles when Thaisa is brought back to life by Cerimon, I cut nearly all Shakespeare’s words,  other than ‘She lives!”: and we danced her back from the dead.         
        `
    },
    {
        title: "Wildcat's Last Waltz",
        images: [require('../assets/directing/wildcats last waltz/1.jpg'),
        require('../assets/directing/wildcats last waltz/2.jpg'),
        require('../assets/directing/wildcats last waltz/3.jpg'),
        require('../assets/directing/wildcats last waltz/4.jpg'),
        require('../assets/directing/wildcats last waltz/5.jpg'),
        require('../assets/directing/wildcats last waltz/6.jpg')
        ],
        description: `Josh wrote this play in 2018 and casually told me in 2019 that he ‘had written a play and he was performing it in a pub”. I had an instinct and I said “I’ll direct it’. Then I read it. And saw it as clear as crystal in my mind’s eye. It’s a rare jewel written and performed by the extraordinary talent of Josh Welch who remains one of the best actors I have ever known or worked with. It is one of my great privileges to have been part of lighting the Wildcat flame.          
        `
    },
    {
        title: "Pericles for Autistic Individuals and Refugee familes",
        images: [require('../assets/directing/pericles autistic/1.jpg'),
        require('../assets/directing/pericles autistic/2.jpg'),
        require('../assets/directing/pericles autistic/3.jpg'),
        require('../assets/directing/pericles autistic/4.jpg'),
        require('../assets/directing/pericles autistic/5.jpg'),
        require('../assets/directing/pericles autistic/6.jpg')
        ],
        description: `Everyone told me not to do this production. Theatre producers told me that no-one would come and that I should stick to ‘popular titles’ whilst friends advised me that it was unadaptable.  

        I have never been so happy to ignore all advice and stick to my instincts. I created the show in 2019 in Sweden and later that year we performed it on tour in Europe in Polish and Romanian. The show has a range of sensory games and musical content that reflects the knowledge and experience of autism I have accumulated over the last two decades. There are games of sadness and grief in contrast to tangos and songs of fishermen. Toward the end of the show whilst in the throes of the play’s blissful reunion we sing each of the autistic participant’s names in celebration of their lives.  
        
        The production paired with our mainstage Pericles and the same company of actors performed them both together on the same day at the Craiova International Shakespeare Festival, May 2022. We then drove from Craiova, crossing the Danube to Sofia Bulgaria to perform our Pericles with Ukrainian refugees in response to the war. We have been returning to these families ever since. These performances led to our Double Pericles tour in 2023 which took us across UK, Romania, Bulgaria and Serbia,  playing international Shakespeare festivals, tiny refugee centres and special schools in Tower Hamlets. The Shakespeare Birthplace Trust opened Hall’s Croft for us and we performed for the refugee families of Stratford upon Avon. 
        
        So many people told me not to make a production of Pericles because no-one would want to see it. The music of the spheres told me otherwise. So many theatres turned us down even when we had funding to tour, saying they would rather keep their theatres dark. But me, Joshua Welch and Natasha Haward booked a tour anyway and it's been unforgettable. 
                 
        `
    },
    {
        title: "The Tempest for Autistic Individuals",
        images: [require('../assets/directing/tempest autistic/1.jpg'),
        require('../assets/directing/tempest autistic/2.jpg'),
        require('../assets/directing/tempest autistic/3.jpg'),
        require('../assets/directing/tempest autistic/4.jpg'),
        require('../assets/directing/tempest autistic/5.jpg'),
        require('../assets/directing/tempest autistic/6.jpg')
        ],
        description: `I made this show in 2014 based on sensory games I had started to invent in 2003, so it was a long time coming. At the centre of the show is a game where Miranda teaches Caliban how to say his name and “how to name the bigger light and how the less that burn by day and night”. 

        This scene isn’t in Shakespeare’s original play but is so vividly spoken of by Miranda and Caliban within the scenes and is one of the first games I ever invented for autistic individuals, empowering them with the opportunity to be the “teacher”. 
        
        I have continued to direct this production around the world in many different languages for the last nine years, most recently at Teatro La Plaza in Lima Peru.  With each new production, the show develops depending on the responses to the games of the autistic individuals and the talents and creativity of the actors, especially in the realm of the music. Notably in 2017, I directed the show in Catalan at Teatre Lliure, an experience that totally changed my life and gave me renewed confidence that these productions for marginalised audiences could be an ongoing endeavour. The Catalan actors literally opened up my own musicality and showed me  “riches, ready to drop upon me”. 
        
        `
    },
    {
        title: "A Midsummer Night's Dream for Autistic Individuals",
        images: [require('../assets/directing/autistic midsummer/1.jpg'),
        require('../assets/directing/autistic midsummer/2.jpg'),
        require('../assets/directing/autistic midsummer/3.jpg'),
        require('../assets/directing/autistic midsummer/4.jpg'),
        require('../assets/directing/autistic midsummer/5.jpg'),
        require('../assets/directing/autistic midsummer/6.jpg')
        ],
        description: `This show, created in 2017 was the second fully adapted production I made for autistic individuals using the Hunter Heartbeat games that I had first created during 2003-2006. The show was a collaboration between Flute Theatre and the Orange Tree Theatre in 2017 and went on to perform at the Orange Tree again in 2018 as well as the Bridge Theatre in 2018 and 2019, Chichester Festival Theatre, St John’s College Brighton and The Heart of America Shakespeare Festival, Kansas MO.

        Whilst performing The Tempest in Barcelona in Spring 2017, Anna, an autistic girl, had asked us “to make her a hugging game next time we came to see her”. Later that same year, I was creating A Midsummer Night’s Dream and we made Anna her hugging game insprired by Bottom’s instruction to the fairy Cobweb:
        “Mounsieur Cobweb, good mounsieur, get you your weapons in your hand, and kill me a red-hipped humble-bee on the top of a thistle; and, good
        mounsieur, bring me the honey-bag”.  
        
        One actor plays the bee whilst the other plays Cobweb who wraps the bee in her thread and squeezes her to death “with love’. This game became a firm favourite with children and Anna still loves it to this day. 
        
        The production was also the basis for Flute’s “Deconstructing the Dream” in 2019, a collaboration of science and art that included the first ever live brain imaging of an actor onstage. The actor played Bottom whilst the neuroscientists, who were onstage simultaneously recording the data from the actor – which was being relayed on a huge brain projected on a screen - played the fairies. 

        We have revived the production in 2024 to perform alongside our new mainstage production of A Midsummer NIght's Dream. In Spring  2024 we performed the show  in Romanian with our families from ANCAAR in Craiova and at Riverside Studios London and Brunswick Theatre Brighton with our UK families.

        
        `
    },
    {
        title: "Twelfth Night for Autistic Individuals",
        images: [require('../assets/directing/autistic twelfth night/1.webp'),

        ],
        description: `Flute’s fourth production for autistic individuals will open at the Orange Tree Theatre Richmond on January 29th 2024. 
        `
    },


]

export default productions;