import React from 'react'
import Timeline from '../components/Timeline'
import { Helmet } from 'react-helmet-async'
import HeroSection from '../components/HeroSection'

export default function About() {
  return (
    <div className='flex flex-col w-screen md:border-4 border-t-0 border-white'>
      <Helmet>
        <title>Kelly Hunter - About Me</title>
        <meta name='description' content='I began working in theatre aged 17 playing The Mistress in Evita in the West End in 1980. Since then I have been creatively engaged in theatres, performing and adapting and directing Shakespeare.  I’ve taught and directed students across the world and I’ve also written two books about Shakespeare. ' />

        <meta name='keywords' content='Shakespeare, theatre, director, Flute Theatre, sensory games, theatre for autistic individuals' />
        <link rel='canonical' href='https://www.kellyhunter.co.uk/about' />
      </Helmet>
      <HeroSection title='About me' image='https://kelly-hunter-portfolio-assets.ams3.cdn.digitaloceanspaces.com/about-me.webp'>
      I began working in theatre aged 17 playing The Mistress in Evita in the West End in 1980. Since then I have been creatively engaged in theatres, performing and adapting and directing Shakespeare.  I’ve taught and directed students across the world and I’ve also written two books about Shakespeare. 

Everything I have created has been possible because of what came before it. My musicality and experience of the discipline of musical theatre set me on a path to hearing and seeing the ‘deep music of Shakespeare’. This led me to create sensory games derived from Shakespeare that can be played by anyone, regardless of which language they speak and including those who are autistic. These sensory games led me to creating whole theatrical productions of Shakespeare that can be accessed by those who are marginalized and have no access to the arts. 

Through my ongoing work with Flute Theatre I have experienced the power that Shakespeare’s plays can bring to bear, when brutal honesty is at the heart of an endeavor. 

I left school when I was 15. I am and I continue to be self-taught. 
      </HeroSection>
      
      <div className='bg-green md:p-6 border-white border-t-4 border-white'>
        <h2 className='josefin-font text-6xl text-center font-bold text-white md:p-10 pt-20 overflow-x-hidden'>Timeline</h2>
        <div className='bg-white p-2 md:p-10 relative'>
 
          <Timeline />
        </div>
      </div>
      
      
    </div>
  )
}
