import React, { Suspense } from 'react'
import productions from '../components/productionsData'
import Productions from '../components/Productions'
import {fluteProductions, otherProductions} from '../components/otherProductionsData'
import shakespeareBrainImg from '../assets/education/best-brain_orig.jpg'
import { Helmet } from 'react-helmet-async'
import { Link, Outlet } from 'react-router-dom';
import HeroSection from '../components/HeroSection'
import Loading from '../components/Loading'
export default function Directing() {
  return (
    <div className='w-full '>
        <Helmet>
        {/* Meta tags for description, keywords, and canonical link */}
        <title>Theatre Direction - Kelly Hunter</title>
        <meta
          name='description'
          content="Explore Kelly Hunter’s theatre direction journey, including Flute Theatre's productions of Shakespeare for autistic individuals, and her contributions to sensory games in theatre."
        />
        <meta
          name='keywords'
          content='Shakespeare, theatre, director, Flute Theatre, sensory games, theatre for autistic individuals'
        />
        <link rel='canonical' href='https://www.kellyhunter.co.uk/directing' />
        {/* Open Graph (OG) meta tags */}
        <meta property='og:title' content='Theatre Direction - Kelly Hunter' />
        <meta
          property='og:description'
          content="Explore Kelly Hunter’s theatre direction journey, including Flute Theatre's productions of Shakespeare for autistic individuals, and her contributions to sensory games in theatre."
        />
        <meta property='og:image' content='https://kelly-hunter-portfolio-assets.ams3.cdn.digitaloceanspaces.com/assets/directing/theatre-direction-main.webp' />
        <meta property='og:url' content='https://www.kellyhunter.co.uk/directing' />
        <meta property='og:type' content='website' />
        {/* Twitter meta tags */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:site' content='@KKellyhunter' />
        <meta
          name='twitter:title'
          content='Theatre Direction - Kelly Hunter'
        />
        <meta
          name='twitter:description'
          content="Explore Kelly Hunter’s theatre direction journey, including Flute Theatre's productions of Shakespeare for autistic individuals, and her contributions to sensory games in theatre."/>
        <meta name='twitter:image' content='https://kelly-hunter-portfolio-assets.ams3.cdn.digitaloceanspaces.com/assets/directing/theatre-direction-main.webp' />
      </Helmet>
      <Suspense fallback={<Loading />}>
       <Outlet />
        <div className=''>
            <HeroSection image={require('../assets/directing/dir-the.webp')} title="Theatre Direction">
            <p className='md:leading-loose'>
                {`I founded Flute Theatre in 2014 as a space to create original and unique productions of Shakespeare. As the Artistic Director I have created and directed eight shows which have toured around the world. Four productions have been specifically made for autistic individuals using the Hunter Heartbeat method. Four productions have been adapted for general audiences and have played extensively on the European Shakespeare festival circuit. Additionally, Flute Theatre performs my productions of Shakespeare for and with refugee families. `}

            </p>
            </HeroSection>
            

            {/* shakespeare productions */}
            <div className='bg-blue border-4 border-white'>
                <h2 className='md:p-20 p-10 text-white josefin-font text-2xl md:text-6xl text-center'>Productions</h2>
            </div>
            <div className='border-4 border-white flex flex-col p-2 md:p-20'>
                <div className='w-full flex items-center justify-center px-2 border-b pb-10'>
                <iframe className='w-full md:w-2/3 lg:w-1/2 h-[40vh]'  src="https://www.youtube.com/embed/QfXpj8io3is?si=wx39U7eWoLp4-0uU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div className='mx-auto py-8 grid grid-cols-1 md:grid-cols-3 '>
                    {productions.map((production, index) => (
                        <div className='h-auto'>
                        <Productions key={`prods_${index}`} data={production} />
                        </div>
                    ))}
                </div>
                <div className='flex md:w-4/5 h-80 bg-blue bg-center p-4 m-auto' style={{backgroundImage: `url(${shakespeareBrainImg})`}}>
                    
                    <div className='md:w-1/2 text-white m-auto text-center space-y-2'>
                        <h3 className='josefin-font font-bold text-xl'>Deconstructing the Dream</h3>
                        <p className='raleway-font text-xs'>In Deconstructing the Dream, we took some of these sensory games as a starting point for the neuroscience research. Each game involves a structured but playful social interaction which is short and repeatable, creating the ideal stimuli for scientific investigation.</p>
                        <div className='raleway-font font-bold '><Link className='border-b-4 border-green' to='/education'>Learn More &rarr;</Link></div>
                    </div>

                </div>
            </div>

            {/* flute productions */}
            <div className='bg-green border-4 border-white flex flex-wrap p-2 md:p-40 gap-8  text-center'>
                <div className=' md:columns-3 space-y-4'>
                    {fluteProductions.map((production, index) => (
                        <div className='h-auto w-auto  break-inside-avoid-column'>
                        <div key={`fluteProds_${index}`} className='border-dashed border-2 rounded-md border-white p-6 text-white josefin-font'>
                            <h3 className='font-bold text-2xl pb-4'>{production.title}</h3>
                            <div className='px-4'>
                            {production.events.map((eventLocation, index) => (
                                <p key={`fluteEvents_${index}`} className='raleway-font text-sm p-2'>{eventLocation}</p>
                            ))}
                            </div>
                       </div>
                    </div>
                    ))}
                </div>
            </div>

            {/* other productions */}
            <div className='bg-white border-4 border-white flex flex-wrap p-2 md:p-40 gap-8  text-center'>
                <h2 className='col-span-2 text-blue text-3xl raleway-font font-bold pb-10 m-auto'>Other Productions</h2>

                <div className=' md:columns-5 space-y-4'>
                    {otherProductions.map((production, index) => (
                        <div className='h-auto w-auto  break-inside-avoid-column'>
                        <div key={`fluteProds_${index}`} className='border-dashed border-2 rounded-md border-blue p-4 josefin-font'>
                            <h3 className='font-bold text- '>{production.title}</h3>
                            {production.events.map((eventLocation, index) => (
                                <p key={`fluteEvents_${index}`} className='raleway-font text-xs py-2'>{eventLocation}</p>
                            ))}
                       </div>
                    </div>
                    ))}
                </div>
            </div>
        </div>
        </Suspense>
    </div>
  )
}
