import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
export default function ProductionPopUp() {
  const location = useLocation();
  const navigate = useNavigate();
  const { title, images, description, reviews, link, type } = location.state;

  // State to keep track of the current image index
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // State to manage button visibility
  const [hideButtons, setHideButtons] = useState(false);
  const [hidePreviousButton, setHidePreviousButton] = useState(false);

  // Function to handle moving to the next image
  const goToNextImage = () => {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
      setHideButtons(false); // Show buttons when moving to the next image
    } else {
      setHideButtons(true);
    }
    setHidePreviousButton(false); // Always reset the previous button visibility
  };

  // Function to handle moving to the previous image
  const goToPreviousImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
      setHidePreviousButton(false); // Show buttons when moving to the previous image
    } else if (currentImageIndex === 0) {
      setHidePreviousButton(true);
    } else {
      setHidePreviousButton(false);
    }
    setHideButtons(false); // Always reset the next button visibility
  };

  const backPage = () => {
    navigate(-1);
  };

  return (
    <div className='fixed top-0 left-0 z-10 h-screen w-screen bg-black bg-opacity-60 md:px-40 flex overflow-y-auto'>
      <div className='bg-white m-auto text-blue md:w-[70%] p-2 md:p-12 flex flex-col space-y-4 relative'>
        <>
          <div className='absolute top-0 right-0 p-4'>
            <button onClick={backPage} className='w-full h-full'>
              Close &#x2715;
            </button>
          </div>

          {/* Image Slideshow */}
          <div className='relative max-md:pt-8'>
            <img
              className='w-full object-contain bg-black h-80 md:h-[500px]'
              src={images[currentImageIndex]}
              alt={`Image ${currentImageIndex + 1}`}
            />

            <div className='absolute top-0 left-0 w-full h-full flex items-center justify-between'>
              {images.length > 1 && !hideButtons && (
                <button className='text-white mix-blend-difference text-4xl h-full w-1/2' onClick={goToPreviousImage}>
                  <div className='float-left'>&#8249;</div>
                </button>
              )}
              {images.length > 1 && !hidePreviousButton && (
                <button className='text-white mix-blend-difference text-4xl h-full w-1/2' onClick={goToNextImage}>
                  <div className='float-right'>&#8250;</div>
                </button>
              )}
            </div>

            {/* Progress Bar */}
            {images.length > 1 && (
              <div className='absolute bottom-0 w-full h-2 bg-blue relative border-b-[0.5px] border-black'>
                <div
                  className='h-full transition duration-300 bg-white'
                  style={{ width: `${((currentImageIndex + 1) / images.length) * 100}%` }}
                />
              </div>
            )}
          </div>

          <h1 className='josefin-font text-2xl font-bold  max-md:px-4'>{title}</h1>
          <p className='leading-loose w-full raleway-font overflow-y-auto max-md:px-4'>{description}</p>

          {type === 'book' && reviews && reviews.map((review, index) => (
          <div key={index} className='bg-blue bg-opacity-10 p-6 rounded-md text-sm space-y-4 raleway-font'>
            <p className='italic'>{review.content}</p>
            <p className='font-bold'>{review.author}</p>
          </div>
        ))}

        {type === 'book' && (
          <div className='max-md:flex'>
            <Link className='raleway-font font-bold border-b-4 border-green text-2xl max-md:m-auto max-md:my-4' to={link}>Buy Now &rarr;</Link>
          </div>
        )}




          <div>
            </div>
        </>
      </div>
    </div>
  );
}
