// Import necessary libraries
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Logo from './Logo'; // Make sure to replace this with the correct path to your Logo component

// Header component
const Header = () => {
  const location = useLocation();
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const isDirectingActive = location.pathname === '/directing';
  const isEducationActive = location.pathname === '/education';
  const isBooksActive = location.pathname === '/books';
  const isHeartbeatActive = location.pathname === '/hunter-heartbeat';

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className='w-full max-md:bg-black max-md:bg-opacity-10 h-full md:border-4 border-white'>
      <nav className='flex w-full h-full josefin-font'>
        {/* Main navigation for larger screens */}
        <ul className='bg-blue w-1/2 flex font-bold text-white border-r-4 border-white hidden md:flex'>
          <li className={`m-auto ${isDirectingActive ? 'underline' : ''}`}>
            <Link to='/directing'>Theatre direction</Link>
          </li>
          <li className={`m-auto ${isEducationActive ? 'underline' : ''}`}>
            <Link to='/education'>Education</Link>
          </li>
          <li className={`m-auto ${isBooksActive ? 'underline' : ''}`}>
            <Link to='/books'>Books</Link>
          </li>
          <li className={`m-auto ${isHeartbeatActive ? 'underline' : ''}`}>
            <Link to='/hunter-heartbeat'>Hunter Heartbeat</Link>
          </li>
          <li className='m-auto'>
            <a href='https://www.flutetheatre.co.uk'>Flute Theatre</a>
          </li>
        </ul>

        {/* Dropdown menu for mobile devices */}
        <div className={`md:hidden w-full h-screen${isDropdownOpen ? ' bg-blur' : ''} text-white`}>
          <button onClick={handleDropdownToggle} className='w-full relative text-center p-4'>
            <div className={`absolute h-full top-0 left-0 flex ${isDropdownOpen ? ' hidden' : ''}`}>
              <span className='m-auto px-2 font-logo border-[0.5px] border-[#FFFAF0] ml-2'>Menu</span>
            </div>
            <div>
              <Logo />
            </div>
          </button>
          <AnimatePresence className=''>
            
            {isDropdownOpen && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                className='w-full h-full space-y-10 raleway-font pt-4 flex flex-col text-center'
              >
                <ul className='list-none h-full space-y-4 font-bold text-2xl'>
                <motion.li
                  key="directing"
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className={`${isDirectingActive ? 'underline' : ''}`}
                >
                  <Link to='/directing' onClick={handleDropdownToggle}>
                    Theatre direction
                  </Link>
                </motion.li>
                <motion.li
                  key="education"
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className={` ${isDirectingActive ? 'underline' : ''}`}
                >
                  <Link to='/education' onClick={handleDropdownToggle}>
                    Education
                  </Link>
                </motion.li>
                <motion.li
                  key="books"
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className={` ${isDirectingActive ? 'underline' : ''}`}
                >
                  <Link to='/books' onClick={handleDropdownToggle}>
                    Books
                  </Link>
                </motion.li>
                <motion.li
                  key="hunter_heartbeat"
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className={` ${isDirectingActive ? 'underline' : ''}`}
                >
                  <Link to='/hunter-heartbeat' onClick={handleDropdownToggle}>
                    Hunter Heartbeat
                  </Link>
                </motion.li>
                <motion.li
                  key="flute_theatre"
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className={` ${isDirectingActive ? 'underline' : ''}`}
                >
                  <a href='https://www.flutetheatre.co.uk' onClick={handleDropdownToggle}>
                    Flute Theatre
                  </a>
                </motion.li>
                <motion.li
                  key="about_me"
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className={` ${isDirectingActive ? 'underline' : ''}`}
                >
                  <Link to='/about-me' onClick={handleDropdownToggle}>
                    About Me
                  </Link>
                </motion.li>
                <motion.li
                  key="education"
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className={` ${isDirectingActive ? 'underline' : ''}`}
                >
                  <Link to='/contact' onClick={handleDropdownToggle}>
                    Contact
                  </Link>
                </motion.li>
                </ul>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* Additional links for larger screens */}
        <div className='w-1/2 flex h-full! text-white hidden md:flex font-bold'>
  <div className='w-3/4'>
    <Link className='w-full h-full flex hover:underline' to='/about-me'>
      <div className='w-full h-full bg-green md:border-r-4 border-white text-center flex items-center justify-center'>
        About Me
      </div>
    </Link>
  </div>
  
  <Link className='w-1/4 bg-blue h-full hover:underline text-center flex items-center justify-center' to='/contact'>
    Contact
  </Link>
</div>

      </nav>
    </div>
  );
};

export default Header;
