import React from 'react'
import heroImg from '../assets/hunter heartbeat/heropng.png'
import { Helmet } from 'react-helmet-async'
import book from '../assets/book-cover.png'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import HeroSection from '../components/HeroSection'

const playlist = [
    <iframe className='w-full h-full' src="https://www.youtube.com/embed/3Td5TUkCJ08?si=rEA3dGNYZL3l7QTq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
    <iframe className='w-full h-full' src="https://www.youtube.com/embed/gNzK7lbrs5w?si=KkhS4_inX5PYwN3y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
    <iframe className='w-full h-full' src="https://www.youtube.com/embed/gRXAoiucGOo?si=Pa6lay9QcGQ2NW_4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
    <iframe className='w-full h-full' src="https://www.youtube.com/embed/IBlT_JuYwmE?si=9PE_lpYIZEeMT6eQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
    <iframe className='w-full h-full' src="https://www.youtube.com/embed/0j_FkQddqlI?si=ZRNNR3_7s2Z_GKzO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>,
]

export default function HunterHeartbeat() {

    
      const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);
    
      const handleVideoButtonClick = (index) => {
        setSelectedVideoIndex(index);
      };


  return (
    <div>
              <Helmet>
        {/* Meta tags for description, keywords, and canonical link */}
        <title>Hunter Heartbeat - Kelly Hunter</title>
        <meta
          name='description'
          content='Explore Kelly Hunter’s Hunter Heartbeat Method, sensory drama games for individuals with autism, and the impact of Flute Theatre.'
        />
        <meta
          name='keywords'
          content='Hunter Heartbeat Method, sensory games, autism, Flute Theatre, Kelly Hunter'
        />
        <link rel='canonical' href='https://www.kellyhunter.co.uk/hunter-heartbeat' />
        {/* Open Graph (OG) meta tags */}
        <meta property='og:title' content='Hunter Heartbeat - Kelly Hunter' />
        <meta
          property='og:description'
          content='Explore Kelly Hunter’s Hunter Heartbeat Method, sensory drama games for individuals with autism, and the impact of Flute Theatre.'
        />
        <meta property='og:image' content={heroImg} />
        <meta property='og:url' content='https://www.kellyhunter.co.uk/hunter-heartbeat' />
        <meta property='og:type' content='website' />
        {/* Twitter meta tags */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:site' content='@KKellyhunter' />
        <meta
          name='twitter:title'
          content='Hunter Heartbeat - Kelly Hunter'
        />
        <meta
          name='twitter:description'
          content='Explore Kelly Hunter’s Hunter Heartbeat Method, sensory drama games for individuals with autism, and the impact of Flute Theatre.'
        />
        <meta name='twitter:image' content={heroImg} />
      </Helmet>
        {/* hero section */}
        <div>
            <HeroSection image='https://kelly-hunter-portfolio-assets.ams3.cdn.digitaloceanspaces.com/heropng.png' title="Hunter Heartbeat">
            The Hunter Heartbeat Method is a series of sensory drama games, which allow autistic individuals to share how it feels to be alive and celebrate their identity. Autistic individuals have created this award-winning methodology across the world, working with me over the last twenty years.
            <iframe className='w-full pt-2 max-md:pb-10' height="315" src="https://www.youtube.com/embed/jfhJIMwkMkw?si=EgB1TGQnrA7ncb2R" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </HeroSection>
        </div>

        {/* video section */}
        <div className='md:flex bg-green text-white h-auto w-full p-10 md:space-x-4  border-4 border-white'>
            <h2 className='md:w-1/6 text-4xl raleway-font my-auto'>Hunter Heartbeat Method</h2>
            <div className='md:w-[60%] flex h-80 md:h-[80vh] '>
            <iframe className='m-auto object-cover h-full w-full' title="vimeo-player" src="https://player.vimeo.com/video/411467621?h=00c3817efb" width="640" height="360" frameBorder="0"    allowFullScreen></iframe>
            </div>
            
            <p className='md:w-1/5  max-md:px-6 py-2 md:py-20  my-auto italic josefin-font font-light leading-loose '>Filmed during the first wave of the pandemic in 2020, I describe the “Conversation of the Body “and the “Choreography of Emotion” that underpins the Hunter Heartbeat games. 
            The second half of the film is from our 2019community project with autistic children from Queensmsill School and their families  at the Bush Theatre, London. 
            </p>
        </div>

            {/* shakespears heartbeat - book */}
        <div className='border-dashed border-2 border-blue my-10 w-3/4 ml-8 max-h-[400px] p-10 flex space-x-4 max-md:hidden'>
            <img className='object-contain w-auto' src={book} alt="Shakespeare's Heartbeat book cover" />
            <div className='space-y-4'>
                <span className='josefin-font text-sm'>Book</span>
                <h3 className='font-bold josefin-font text-3xl'>Shakespeare's Heartbeat</h3>
                <p className='raleway-font text-sm'>
                The Hunter Heartbeat Method is a series of sensory games that I have created for children and young people with autism to play, they are games of humanity that need only the human voice and body and another person to play with. These games are derived from Shakespeare’s poetic exploration of how it feels to be alive.
                </p>
                <p className='italic josefin-font border-2 border-green rounded-md p-4 text-xs'>“When I was 2, my brother was diagnosed with severe autism, and my journey trying to understand  him began. Throughtout my directing career, I have searched for a process of connect the intimacy of theatre to the rigidity of his autism. Kelly Hunter has found that process. There is no more effective way to teach social skills, promote inclusion, and awaken the soul than the Hunter Heartbeat method. The program changed my life, and ever since I started teaching it, it has changed the lives of the hundreds of individuals who have experienced the awakening it provides.”<br /><span className='font-bold'>Ben Raanan</span></p>

                <Link to='/books' className='border-b-4 border-green raleway-font font-bold hover:bg-blue hover:text-white transition duration-300'>Learn More about my books &rarr;</Link>
                <Link>Buy Now</Link>
            </div>
        </div>

        
        
        {/* the documentary */}
        <div className='bg-blue border-x-4 border-t-4 border-white md:flex h-auto md:h-[600px] p-4'>
        <div className='max-md:hidden w-full h-full'>{playlist[selectedVideoIndex]}</div>
            <div className='md:w-1/3 text-white py-6 px-4 flex flex-col space-y-4'>
                <span className='text-sm josefin-font'>Hunter Heartbeat</span>
                <h3 className='font-bold josefin-font text-2xl'>Documentary</h3>
                <div className='md:hidden'>{playlist[selectedVideoIndex]}</div>
                <p className='raleway-font text-sm'>The first film shows the beginnings of Kelly Hunter’s Heartbeat Method from 2002-2012. The second shows our work in Catalonia. The final three films take an in depth look at the impact of Flute Theatre on individuals and their families who are part of our long term community project.</p>
                <ol className='raleway-font list-roman list-inside space-y-4 text-sm'>
                    <li>
                    <button onClick={() => handleVideoButtonClick(0)}>Hunter Heartbeat Beginnings</button>
                    </li>
                    <li>
                    <button onClick={() => handleVideoButtonClick(1)}>Catalonia</button>
                    </li>
                    <li>
                    <button onClick={() => handleVideoButtonClick(2)}>Kourosh</button>
                    </li>
                    <li>
                    <button onClick={() => handleVideoButtonClick(3)}>Marcus</button>
                    </li>
                    <li>
                    <button onClick={() => handleVideoButtonClick(4)}>Lumen</button>
                    </li>
                </ol>
            </div>
        </div>

        <div className='md:flex josefin-font max-md:space-y-4 md:space-x-4 text-blue py-20 px-10'>
            <div className='md:w-1/2 border-2 border-blue p-4 rounded'>
                <p className='italic'>We are beyond grateful for so much of your time, all the team, their skills, talent & compassion. You are all fabulous. The difference your workshops/ performances made to our autistic pupils in an hour was incredible. The confidence was visibly growing and children that don't use their voices were suddenly thrust into this sensory experience and shouting words! Truly remarkable.
As you know, I was genuinely so moved & emotional to witness such a wonderful, positive reaction from the children. I cannot recommend this experience enough; Flute Theatre is a very special company that truly knows how special our children are and exactly where to meet them in their world.
Every autistic child should have the opportunity to experience the magic of the Flute Theatre.
Thank you, thank you, thank you.
<span className='font-bold block pt-2'>Gabrielle Ettridge Teacher, Tower Hamlets</span></p>
            </div>
            <div className='md:w-1/2 border-2 border-blue p-4 rounded'>
                <p className='italic'>Bonjour Kelly, I am not sure that I can find the words to convey my gratitude to yourself and the whole company for yesterday extraordinary experience.
Aimé spoke for himself, and I am reluctant to try to express what I believe he went through.
As for myself, you all touched my soul to the deepest.
I think that what we were invited to be part of yesterday is what the ancient Greeks intended Theatre to be for humanity.
I still feel it in my all “being” this morning waking up from a profound long rest.
<span className='font-bold block pt-2'>Michéle Bosc. Parent of Aimé Bosc Nikolov</span></p>
            </div>
        </div>
    </div>
  )
}
