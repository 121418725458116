import React from 'react';
import Productions from '../components/Productions';
import { Outlet } from 'react-router-dom';
import kelly from '../assets/books/main.jpg'

import HeroSection from '../components/HeroSection';
import { Helmet } from 'react-helmet-async';
const bookObject = [
  {
    images: [require('../assets/cracking-shakespeare.png')],
    title: 'Cracking Shakespeare',
    description: `Cracking Shakespeare serves to demystify the process of speaking Shakespeare's language, offering hands-on techniques for drama students, young actors and directors who are intimidated by rehearsing, performing and directing Shakespeare's plays.
    For some artists approaching Shakespeare, the ability to capture the dynamic movement of thought from mind to mouth, and the paradox of using the formality of verse to express a realistic form of speech, can seem daunting. Cracking Shakespeare includes practical techniques and exercises to solve this dilemma – including supporting online video which demonstrate how to embody Shakespeare's characters in rehearsal and performance – offering a toolkit that will free actors and directors from their fear of Shakespeare.
    The result of thirty years of acting, teaching and directing Shakespeare, Kelly Hunter's Cracking Shakespeare is the ideal textbook for actors and directors looking for new ways to approach Shakespeare's plays in a hands-on, down-to-earth style.`,
    reviews: [
      {
        content: '“Kelly Hunter is an extraordinary actor and teacher who now generously shares the insights, skills and knowledge that she has amassed during the course of her varied career. Having witnessed the power of Kelly’s approaches first-hand I recommend Cracking Shakespeare to anyone who thinks Shakespeare isn’t for them or who simply wants to explore new, innovative approaches to unlocking Shakespeare’s language.”',
        author: 'Gregory Doran, former Artistic Director, Royal Shakespeare Company',
      },
      {
        content: '“As well as being one of the leading Shakespearean actors and directors of her generation, Kelly Hunter is an inspiring teacher. I have seen her lead workshops for a wide range of different constituencies — from people on the autistic spectrum, to deprived and excluded young adults, to research students here at the Shakespeare Institute – and have been impressed anew every time by her ability to share her insights and energies.  I have been studying and watching Shakespeare for more years now than I care to count, and have never come away from one of Kelly’s productions or workshops without feeling I understand his work better for having been there.”',
        author: 'Michael Dobson – Director of the Shakespeare Institute, Stratford-upon-Avon, and Professor of Shakespeare Studies, University of Birmingham',
      },
    ],
    link: 'https://www.amazon.co.uk/dp/B014EF2EEQ?ref_=cm_sw_r_kb_dp_4HA5KAB8H4VNZQQ2KMZX&tag=kp025-21&linkCode=kpe'
  },
  {
    images: [require('../assets/book-cover.png')],
    title: `Shakespeare's Heartbeat`,
    description: `The Hunter Heartbeat Method is a series of sensory games that I have created for children and young people with autism to play, they are games of humanity that need only the human voice and body and another person to play with. These games are derived from Shakespeare’s poetic exploration of how it feels to be alive.`,
    reviews: [
      {
        content: `“I have used some of Hunter's games in my class with both autistic and blind children and, they really do work! I'm so pleased that she has decided to set down all her ideas in one book as for me, working in a special school, there is so much material here that is of huge benefit to children who struggle with social interaction. This should be on the shelf of every SEN teacher as there are activities here that would work on many levels for children both verbal and non verbal, ASD or not. ”`,
        author: 'Special Needs Teacher 2015',
      },
      {
        content: `““When I was 2, my brother was diagnosed with severe autism, and my journey trying to understand  him began. Throughtout my directing career, I have searched for a process of connect the intimacy of theatre to the rigidity of his autism. Kelly Hunter has found that process. There is no more effective way to teach social skills, promote inclusion, and awaken the soul than the Hunter Heartbeat method. The program changed my life, and ever since I started teaching it, it has changed the lives of the hundreds of individuals who have experienced the awakening it provides.”`,
        author: 'Ben Raanan, Director and Advocate',
      },
    ],
    link: 'https://www.amazon.co.uk/dp/B0C5TWBXXZ?ref_=cm_sw_r_kb_dp_FEV22PQC43V6WCQAYK8N&tag=kp025-21&linkCode=kpe'
  },
];



export default function Books() {

  return (
    <div className='w-full'>
      <Helmet>
      <title>Kelly Hunter - Books</title>
        <meta
          name='description'
          content='Explore Kelly Hunter’s books including "Cracking Shakespeare" and "Shakespeare’s Heartbeat," offering insights into Shakespeare’s language and sensory games for individuals with autism.'
        />
        <meta
          name='keywords'
          content='Shakespeare, theatre, director, Flute Theatre, sensory games, theatre for autistic individuals'
        />
        <link rel='canonical' href='https://www.kellyhunter.co.uk/books' />
        <meta property='og:title' content='Kelly Hunter - Books' />
        <meta
          property='og:description'
          content='Explore Kelly Hunter’s books including "Cracking Shakespeare" and "Shakespeare’s Heartbeat," offering insights into Shakespeare’s language and sensory games for individuals with autism.'
        />
        <meta property='og:image' content={kelly} />
        <meta property='og:url' content='https://www.kellyhunter.co.uk/books' />
        <meta property='og:type' content='website' />
        {/* Twitter meta tags */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:site' content='@YourTwitterHandle' />
        <meta name='twitter:title' content='Kelly Hunter - Books' />
        <meta
          name='twitter:description'
          content='Explore Kelly Hunter’s books including "Cracking Shakespeare" and "Shakespeare’s Heartbeat," offering insights into Shakespeare’s language and sensory games for individuals with autism.'
        />
        <meta name='twitter:image' content={kelly} />
      </Helmet>
      <HeroSection image={kelly} title="Books">
      <div className='grid grid-cols-2 border-b-[0.5px] border-blue pb-4'>
            {bookObject.map((book, index) => (
              <Productions data={book} type='book' />
            ))}
          </div>
      </HeroSection>
    
    <Outlet />
  </div>
);
}
